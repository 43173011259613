import {
	CircularProgress,
	Grid,
	makeStyles,
	Theme,
	Button,
} from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import { FormikProps, Field, FieldProps } from "formik";
import React, { useEffect } from "react";
import {
	GetImagesByTagQuery,
	GetImagesByTagQueryVariables,
	GetImagesByTagDocument,
	useGetImagesByTagLazyQuery,
	HeroImageTypes,
	HomeOptionsInput,
	useDeleteImagesMutation,
	useDoctorsLazyQuery,
	CloudinaryResource,
	Templates,
} from "../../../types/graphql-types";
import cloudinaryUtil from "../../../utils/cloudinaryUtil";
import { TextFieldAdapter } from "../../../components/TextField";

const useStyles = makeStyles((theme: Theme) => ({
	imageContainer: {
		height: theme.spacing(20),
		width: theme.spacing(30),
		flex: "0 0 250px",
		position: "relative",
		cursor: "pointer",
		margin: theme.spacing(2),
		borderRadius: theme.shape.borderRadius,
		"&[data-selected='true']": {
			borderStyle: "solid",
			borderWidth: 2,
			borderColor: theme.palette.info.light,
		},
		"&:hover": {
			boxShadow: theme.shadows[5],
		},
	},
	imageWrapper: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"& img": {
			width: "auto",
			height: "auto",
			maxHeight: "90%",
			maxWidth: "90%",
		},
	},
	deleteIcon: {
		position: "absolute",
		top: -15,
		right: -15,
		background: theme.color.red.main,
		borderRadius: "100%",
		height: theme.spacing(3.5),
		width: theme.spacing(3.5),
		color: theme.color.white.main,
		fontSize: theme.typography.pxToRem(10),
		"&:hover": {
			background: theme.color.red.dark,
		},
	},
	confirmDelete: {
		position: "absolute",
		top: -15,
		right: -15,
	},
	deleteButton: {
		marginLeft: theme.spacing(1),
	},
	altText: {
		fontSize: theme.typography.pxToRem(11),
		marginLeft: theme.spacing(2),
	},
	evergladesDoctorSelect: {
		marginLeft: theme.spacing(1),
	},
	evergladesTextEditor: {
		marginLeft: theme.spacing(1),
		"& .MuiInputBase-root": {
			height: theme.spacing(18),
			"& .MuiInputBase-input": {
				marginTop: theme.spacing(2),
				height: theme.spacing(18),
			},
		},
	},
}));

interface ImageGridProps {
	portalId?: string;
	formikBag: FormikProps<HomeOptionsInput>;
	tag: string;
	heroImageType: HeroImageTypes;
	welcomeBannerWidgetIndex: number;
	setAltTextError?: React.Dispatch<React.SetStateAction<boolean>>;
	heroImageOption?: "doctor" | "upload";
}
export const ImageGrid: React.FC<ImageGridProps> = ({
	portalId,
	formikBag,
	tag,
	heroImageType,
	welcomeBannerWidgetIndex,
	setAltTextError,
}) => {
	const classes = useStyles();
	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
	const templateTag =
		heroImageType === HeroImageTypes.Doctor ||
		heroImageType === HeroImageTypes.Upload
			? `${portalId}_${tag}`
			: tag;
	const heroImageFieldPath = `widgets[${welcomeBannerWidgetIndex}].heroImage.${heroImageType}`;

	const [getImagesByTag, { data, loading }] = useGetImagesByTagLazyQuery({
		ssr: false,
	});
	const [getDoctors, { data: doctorData }] = useDoctorsLazyQuery();

	const [
		deleteImagesMutation,
		deleteImagesResponse,
	] = useDeleteImagesMutation();
	const selectedImage =
		formikBag.values.widgets[welcomeBannerWidgetIndex]?.heroImage?.[
			`${heroImageType}`
		];
	useEffect(() => {
		getImagesByTag({
			variables: {
				tag: templateTag,
			},
		});
	}, [getImagesByTag, templateTag]);

	useEffect(() => {
		setShowDeleteConfirm(false);
	}, [selectedImage]);

	useEffect(() => {
		if (portalId && heroImageType === HeroImageTypes.Doctor) {
			getDoctors({
				variables: {
					portalId,
					includeHeadShot: true,
					includeBio: true,
				},
			});
		}
	}, [getDoctors, heroImageType, portalId]);

	if (loading || deleteImagesResponse.loading)
		return <CircularProgress size={50} />;

	const handleDeleteImage = async () => {
		if (selectedImage?.publicId) {
			await deleteImagesMutation({
				variables: {
					publicIds: [selectedImage.publicId],
				},
				update: (proxy, { data }) => {
					// Read the data from our cache for this query.
					const readQueryData = proxy.readQuery<
						GetImagesByTagQuery,
						GetImagesByTagQueryVariables
					>({
						query: GetImagesByTagDocument,
						variables: {
							tag: templateTag,
						},
					});
					// Write our data back to the cache with the new image in it
					if (readQueryData?.getImagesByTag && data) {
						proxy.writeQuery<GetImagesByTagQuery, GetImagesByTagQueryVariables>(
							{
								query: GetImagesByTagDocument,
								variables: {
									tag: templateTag,
								},
								data: {
									__typename: "Query",
									getImagesByTag: readQueryData.getImagesByTag.filter(
										(image) => image.publicId !== selectedImage?.publicId
									),
								},
							}
						);
					}
				},
			});
			formikBag.setFieldValue(`${heroImageFieldPath}.publicId`, "");
			formikBag.setFieldValue(`${heroImageFieldPath}.altText`, "");
			formikBag.submitForm();
		}
	};
	const selectedDoctorImage = doctorData?.doctors.map(
		(doc) =>
			formikBag.values.widgets[welcomeBannerWidgetIndex].heroImage?.doctor
				?.selectedDoctorNpi === doc.npi && doc.headShot
	);
	const images = [
		...((selectedDoctorImage || []) as CloudinaryResource[]),
		...((data?.getImagesByTag || []) as CloudinaryResource[]),
	].filter((n) => n) as CloudinaryResource[];

	return (
		<Grid item xs={9}>
			<Grid xs={12}>
				{heroImageType === HeroImageTypes.Doctor && (
					<Field
						name={`${heroImageFieldPath}.selectedDoctorNpi`}
						className={classes.evergladesDoctorSelect}
						select
						label="Select a Doctor"
						component={TextFieldAdapter}
						options={doctorData?.doctors.map((doc) => ({
							label: `${doc.firstName} ${doc.lastName}, ${doc.designation}`,
							value: doc.npi,
						}))}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							formikBag.setFieldValue(
								`${heroImageFieldPath}.selectedDoctorNpi`,
								e.target.value
							);
							formikBag.setFieldValue(
								`${heroImageFieldPath}.publicId`,
								`doctorphotos/${e.target.value}`
							);
							doctorData?.doctors.forEach((doc) => {
								if (doc.npi === e.target.value)
									formikBag.setFieldValue(
										`${heroImageFieldPath}.altText`,
										`${doc.firstName} ${doc.lastName}, ${doc.designation}`
									);
							});
						}}
					/>
				)}
				{(formikBag.values.template === Templates.Boston ||
					formikBag.values.template === Templates.Atlanta ||
					formikBag.values.template === Templates.Chicago ||
					formikBag.values.template === Templates.Everglades) && (
					<Field
						name={`${heroImageFieldPath}.text`}
						className={classes.evergladesTextEditor}
						label="Intro/Mission Statement"
						component={TextFieldAdapter}
						multiline={true}
						rows={10}
					/>
				)}
			</Grid>
			<Grid container spacing={1}>
				{!(heroImageType === HeroImageTypes.Doctor) &&
					images.map((image) => (
						<Grid item key={image.publicId}>
							<Field name="widgets">
								{({ form }: FieldProps) => (
									<div
										className={classes.imageContainer}
										data-selected={selectedImage?.publicId === image.publicId}
										onClick={() => {
											form.setFieldValue(
												`${heroImageFieldPath}.altText`,
												image?.altText
											);
											form.setFieldValue(
												`${heroImageFieldPath}.publicId`,
												image?.publicId
											);
											form.setFieldValue(
												`widgets[${welcomeBannerWidgetIndex}].heroImage.type`,
												heroImageType
											);
											setAltTextError && setAltTextError(false);
										}}
									>
										<div className={classes.imageWrapper}>
											<img
												id={`image_${image.publicId}`}
												src={cloudinaryUtil.imageSrc({
													version: image?.version,
													publicId: image.publicId,
													transforms: "ar_16:9,c_fill,g_auto/c_scale,w_320",
												})}
												alt={image.altText || ""}
											/>
										</div>
										<div>
											{selectedImage?.publicId === image.publicId && portalId && (
												<>
													{!showDeleteConfirm ? (
														<Clear
															className={classes.deleteIcon}
															onClick={() => setShowDeleteConfirm(true)}
														/>
													) : (
														<div className={classes.confirmDelete}>
															<Button
																color="primary"
																variant="contained"
																onClick={() => setShowDeleteConfirm(false)}
															>
																Cancel
															</Button>
															<Button
																className={classes.deleteButton}
																color="secondary"
																variant="contained"
																onClick={() => handleDeleteImage()}
															>
																Delete
															</Button>
														</div>
													)}
												</>
											)}
										</div>
									</div>
								)}
							</Field>
							{selectedImage?.publicId === image.publicId && (
								<div className={classes.altText}>{image.altText}</div>
							)}
						</Grid>
					))}
			</Grid>
		</Grid>
	);
};
