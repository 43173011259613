import React from "react";
import * as Yup from "yup";
import { useRouteMatch } from "react-router-dom";
import { EditorState } from "draft-js";
import { Formik, Field, FieldProps } from "formik";
import {
	makeStyles,
	Theme,
	Button,
	CircularProgress,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormControl,
	FormControlLabel,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Switch,
} from "@material-ui/core";
import {
	RichTextEditorAdapter,
	deserialize,
	serialize,
	getWordCount,
} from "../../components/RichTextEditor";
import {
	useEditDoctorsChairMutation,
	DoctorsChairDocument,
	DoctorsChairOptionDocument,
	DoctorsChairOptionQuery,
	DoctorsChairOptionQueryVariables,
	ServiceWithChildrenFragment,
	ServiceWithoutChildrenFragment,
	DoctorsChairDoctorFragment,
	Doctor,
} from "../../types/graphql-types";
import { useApolloClient } from "@apollo/client/react/hooks";
import { formatDoctorName } from "../../utils/formatName";
import { mapGraphQlError } from "../../utils/errorHandler";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";

const useStyles = makeStyles((theme: Theme) => ({
	select: {
		minWidth: 240,
		paddingBottom: theme.spacing(2),
	},
	text: {
		width: "100%",
	},
	rte: {
		minHeight: "10rem",
	},
	loader: {
		margin: theme.spacing(0, 1, 0, 1),
	},
}));

interface DoctorsChairFormValues {
	enabled: boolean;
	doctor: string;
	text: EditorState;
}

interface DoctorsChairDialogProps {
	parent: ServiceWithChildrenFragment;
	child: ServiceWithoutChildrenFragment;
	doctors: DoctorsChairDoctorFragment[];
	initialValues: DoctorsChairFormValues;
	handleClose: () => void;
}

export const DoctorsChairDialog: React.FC<DoctorsChairDialogProps> = ({
	parent,
	child,
	doctors,
	initialValues,
	handleClose,
}) => {
	const classes = useStyles();
	const match = useRouteMatch<{ portalId?: string }>();
	const portalId = match?.params.portalId || "";

	const client = useApolloClient();
	const { setSnackbarProps } = useGlobalSnackbar();

	const [editDoctorsChair] = useEditDoctorsChairMutation({
		refetchQueries: [
			{
				query: DoctorsChairDocument,
				variables: {
					portalId,
					taxonomyId: parent.taxonomyId,
				},
			},
		],
		awaitRefetchQueries: true,
	});

	return (
		<Formik<DoctorsChairFormValues>
			initialValues={initialValues}
			enableReinitialize={true}
			onSubmit={async (values: DoctorsChairFormValues) => {
				const result = await editDoctorsChair({
					variables: {
						portalId,
						taxonomyId: child.taxonomyId,
						enabled: values.enabled,
						npi: values.doctor,
						text: serialize(values.text),
					},
				});
				if (result?.data) {
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: true,
						message: "Doctor's Chair Saved!",
					});
				} else {
					const error =
						result?.errors?.[0] && mapGraphQlError(result.errors[0]);
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: false,
						message: error?.displayableError || "Doctor's Chair Not Saved!",
					});
				}
				handleClose();
			}}
			validationSchema={Yup.object().shape<Partial<DoctorsChairFormValues>>({
				doctor: Yup.mixed().when("enabled", (enabled: boolean) =>
					enabled ? Yup.string().required("Required") : Yup.string()
				),
				text: Yup.mixed().when(
					["doctor", "enabled"],
					(doctor: string, enabled: boolean) =>
						!!doctor && !!enabled
							? Yup.object()
									.test(
										"Required",
										"Required",
										(text: EditorState) =>
											text.getCurrentContent().getPlainText().trim().length > 0
									)
									.test(
										"word count",
										"Text snippets must be 200 words or less",
										(text: EditorState) => {
											return getWordCount(text.getCurrentContent()) <= 200;
										}
									)
							: Yup.object()
				),
			})}
		>
			{({ values, touched, errors, handleSubmit, isSubmitting }) => (
				<Dialog open fullWidth maxWidth="sm">
					<DialogTitle>
						<Field name="enabled">
							{({ field }: FieldProps) => (
								<FormControlLabel
									aria-label={`Doctor's Chair for ${child.text}`}
									label={`Doctor's Chair for ${child.text}`}
									control={<Switch {...field} checked={field.value} />}
								/>
							)}
						</Field>
					</DialogTitle>
					<DialogContent>
						{values.enabled && (
							<>
								<Field name="doctor">
									{({ field, meta, form }: FieldProps) => (
										<FormControl
											className={classes.select}
											error={!!(meta.touched && meta.error)}
										>
											<InputLabel>Doctor</InputLabel>
											<Select
												{...field}
												fullWidth
												onChange={async (
													e: React.ChangeEvent<{
														name?: string | undefined;
														value: unknown;
													}>
												) => {
													field.onChange(e);
													form.setStatus({ loading: { text: true } });
													const { data } = await client.query<
														DoctorsChairOptionQuery,
														DoctorsChairOptionQueryVariables
													>({
														query: DoctorsChairOptionDocument,
														variables: {
															npi: e.target.value as string,
															taxonomyId: child.taxonomyId || "",
														},
													});
													form.setStatus({ loading: { text: false } });
													if (
														typeof data?.doctorsChairOption.text === "string"
													) {
														form.setFieldValue(
															"text",
															deserialize(data.doctorsChairOption.text)
														);
													}
												}}
											>
												{doctors.map((doctor: Doctor) => (
													<MenuItem key={doctor.npi} value={doctor.npi}>
														{formatDoctorName(doctor, false, true)}
													</MenuItem>
												))}
											</Select>
											{!!(meta.touched && meta.error) && (
												<FormHelperText>{meta.error}</FormHelperText>
											)}
										</FormControl>
									)}
								</Field>
								{values.doctor && (
									<FormControl className={classes.text}>
										<Field
											name="text"
											className={classes.rte}
											component={RichTextEditorAdapter}
											wordLimit={200}
										/>
										{touched.text && errors.text && (
											<FormHelperText error>{errors.text}</FormHelperText>
										)}
										<FormHelperText>
											* Updates the selected doctor's text snippet for the
											current service across all practices
										</FormHelperText>
									</FormControl>
								)}
							</>
						)}
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							color="secondary"
							onClick={handleClose}
							disabled={isSubmitting}
						>
							cancel
						</Button>
						<Button
							variant="contained"
							color="primary"
							onClick={() => handleSubmit()}
							disabled={isSubmitting || !!errors.text}
						>
							save
							{isSubmitting && (
								<CircularProgress size={16} className={classes.loader} />
							)}
						</Button>
					</DialogActions>
				</Dialog>
			)}
		</Formik>
	);
};
