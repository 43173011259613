import React from "react";
import { Field, FieldProps } from "formik";
import { startCase } from "lodash";

import {
	makeStyles,
	Grid,
	FormControlLabel,
	Checkbox,
	TextField,
	MenuItem,
	Theme,
} from "@material-ui/core";

import { DayHoursInput } from "../../types/graphql-types";

const useStyles = makeStyles((theme: Theme) => ({
	row: {
		paddingBottom: theme.spacing(1.5),
		paddingLeft: theme.spacing(0.5),
		paddingRight: theme.spacing(0.5),

		"&:nth-child(odd)": {
			backgroundColor: theme.color.grey.light,
		},
	},
	column: {
		paddingLeft: theme.spacing(2),
		paddingRight: theme.spacing(2),
	},
	select: {
		width: "100%",
		paddingBottom: theme.spacing(0.5),
	},
	dayTitle: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
	dayTitleText: {
		fontWeight: theme.typography.fontWeightBold,
		paddingLeft: theme.spacing(2),
	},
}));

type DayOfWeekProps = FieldProps<DayHoursInput>;

const availableTime: string[] = [
	"6:00 AM",
	"6:30 AM",
	"7:00 AM",
	"7:30 AM",
	"8:00 AM",
	"8:30 AM",
	"9:00 AM",
	"9:30 AM",
	"10:00 AM",
	"10:30 AM",
	"11:00 AM",
	"11:30 AM",
	"12:00 PM",
	"12:30 PM",
	"1:00 PM",
	"1:30 PM",
	"2:00 PM",
	"2:30 PM",
	"3:00 PM",
	"3:30 PM",
	"4:00 PM",
	"4:30 PM",
	"5:00 PM",
	"5:30 PM",
	"6:00 PM",
	"6:30 PM",
	"7:00 PM",
	"7:30 PM",
	"8:00 PM",
];
const DEFAULT_OPEN = "8:00 AM";
const DEFAULT_CLOSE = "5:00 PM";

export const DayOfWeek: React.FC<DayOfWeekProps> = ({
	field: { name: dayName, value },
	form,
}) => {
	const classes = useStyles();
	const enabled = Boolean(value);

	const onEnableDay = (enabled: boolean) => {
		form.setFieldValue(
			dayName,
			enabled
				? ({
						open: DEFAULT_OPEN,
						close: DEFAULT_CLOSE,
				  } as DayHoursInput)
				: null
		);
	};

	return (
		<Grid key={dayName} className={classes.row} item={true} xs={12}>
			<Grid container={true}>
				<Grid item={true} xs={12} className={classes.dayTitle}>
					<span className={classes.dayTitleText}>{startCase(dayName)}</span>
					<FormControlLabel
						control={
							<Checkbox
								checked={enabled}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									onEnableDay(e.target.checked);
								}}
							/>
						}
						label="Open"
					/>
				</Grid>
				{!!enabled && (
					<React.Fragment>
						<Grid item={true} xs={6} className={classes.column}>
							<Field name={`${dayName}.open`}>
								{({ field }: FieldProps) => (
									<TextField
										className={classes.select}
										select={true}
										label="Open"
										{...field}
									>
										{availableTime.map((option: string) => {
											return (
												<MenuItem key={option} value={option.toUpperCase()}>
													{option}
												</MenuItem>
											);
										})}
									</TextField>
								)}
							</Field>
						</Grid>
						<Grid item={true} xs={6} className={classes.column}>
							<Field name={`${dayName}.close`}>
								{({ field }: FieldProps) => (
									<TextField
										className={classes.select}
										select={true}
										label="Close"
										{...field}
									>
										{availableTime.map((option: string) => {
											return (
												<MenuItem key={option} value={option.toUpperCase()}>
													{option}
												</MenuItem>
											);
										})}
									</TextField>
								)}
							</Field>
						</Grid>
					</React.Fragment>
				)}
			</Grid>
		</Grid>
	);
};
