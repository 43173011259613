import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Button,
} from "@material-ui/core";

interface RemoveTeamMemberDialogProps {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	portalId: string;
	id: string;
	onDeleteTeamMember: (id: string) => void;
}

export const RemoveTeamMemberDialog: React.FC<RemoveTeamMemberDialogProps> = ({
	open,
	setOpen,
	id,
	onDeleteTeamMember,
}) => {
	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>Are you sure want to remove a member?</DialogTitle>
			<DialogContent>
				<DialogContentText>
					This action can not be rolled back.
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)} color="secondary">
					No
				</Button>
				<Button
					onClick={() => onDeleteTeamMember(id)}
					color="primary"
					variant="contained"
				>
					Yes, Remove this Member
				</Button>
			</DialogActions>
		</Dialog>
	);
};
