import { Grid, TextField, makeStyles, Theme } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { ImageUpload } from "../../../components/ImageUpload/ImageUpload";
import { mapGraphQlError } from "../../../utils/errorHandler";
import {
	GetImagesByTagDocument,
	GetImagesByTagQuery,
	GetImagesByTagQueryVariables,
	HeroImageTypes,
	HomeOptionsInput,
	useUploadImageMutation,
} from "../../../types/graphql-types";
import cloudinaryUtil from "../../../utils/cloudinaryUtil";

const useStyles = makeStyles((theme: Theme) => ({
	imageContainer: {
		flex: "0 0 200px",
		position: "relative",
	},
	imageWrapper: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"& img": {
			width: "95%",
			height: "auto",
		},
		marginBottom: "1rem",
		objectFit: "contain",
	},
	sidebarContainer: {
		borderRight: `solid 1px #e0e0e0`,
		paddingRight: theme.spacing(4),
	},
	uploadNewImageTitle: {
		margin: 0,
		borderTop: `1px solid ${theme.palette.grey[200]}`,
		paddingTop: theme.spacing(2),
	},
}));

interface HeroImageSidebarProps {
	portalId?: string;
	formikBag: FormikProps<HomeOptionsInput>;
	tag: string;
	heroImageType?: HeroImageTypes;
	welcomeBannerWidgetIndex: number;
	altTextError?: boolean;
	setAltTextError?: React.Dispatch<React.SetStateAction<boolean>>;
	showAltTextField?: boolean;
	heroImageOption?: string;
}

const HeroImageSidebar: React.FC<HeroImageSidebarProps> = ({
	portalId,
	formikBag,
	tag,
	heroImageType,
	welcomeBannerWidgetIndex,
	altTextError,
	setAltTextError,
	showAltTextField = false,
	heroImageOption,
}) => {
	const classes = useStyles();
	const [uploadImageMutation] = useUploadImageMutation();
	const handleUploadImage = async (file: File) => {
		if (portalId) {
			const heroId =
				`${tag}_` +
				Math.random().toString(36).substring(2, 15) +
				Math.random().toString(36).substring(2, 15);
			const { publicId, tags } = cloudinaryUtil.customHeroImage(
				portalId,
				heroId,
				tag
			);
			const result = await uploadImageMutation({
				variables: {
					file,
					publicId,
					tags,
					altText: "",
					requestAdvFace: true,
				},
				update: (proxy, { data }) => {
					// Read the data from our cache for this query.
					const readQueryData = proxy.readQuery<
						GetImagesByTagQuery,
						GetImagesByTagQueryVariables
					>({
						query: GetImagesByTagDocument,
						variables: {
							tag: `${portalId}_${tag}`,
						},
					});
					// Write our data back to the cache with the new image in it
					if (readQueryData?.getImagesByTag && data) {
						proxy.writeQuery<GetImagesByTagQuery, GetImagesByTagQueryVariables>(
							{
								query: GetImagesByTagDocument,
								variables: {
									tag: `${portalId}_${tag}`,
								},
								data: {
									__typename: "Query",
									getImagesByTag: [
										data.uploadImage,
										...readQueryData.getImagesByTag,
									],
								},
							}
						);
					}
				},
			});
			if (result) {
				// TODO: is there something clever we can do here?
			}
			if (result?.errors) {
				result.errors[0] && mapGraphQlError(result.errors[0]);
			}
		}
	};
	const heroImageValue =
		formikBag.values.widgets[welcomeBannerWidgetIndex]?.heroImage?.[
			`${heroImageType}`
		];

	return (
		<Grid item xs={3} className={classes.sidebarContainer}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<h3 style={{ margin: 0 }}>Hero Image</h3>
				</Grid>
				<Grid item container xs={12}>
					{heroImageValue?.publicId ? (
						<>
							<div className={classes.imageContainer}>
								<div className={classes.imageWrapper}>
									<img
										id={`image_${heroImageValue.publicId}`}
										src={cloudinaryUtil.imageSrc({
											publicId:
												heroImageType === HeroImageTypes.Doctor
													? `c_fill,f_auto,fl_lossy,g_face,h_540,q_auto,w_960/${heroImageValue.publicId}`
													: heroImageValue.publicId,
										})}
										alt={heroImageValue.altText}
									/>
								</div>
							</div>
							{showAltTextField && (
								<TextField
									name={`widgets[${welcomeBannerWidgetIndex}].heroImage.${heroImageType}.altText`}
									label="Alt Text"
									value={heroImageValue?.altText ?? ""}
									onChange={(e) => {
										setAltTextError && setAltTextError(false);
										formikBag.setFieldValue(
											`widgets[${welcomeBannerWidgetIndex}].heroImage.${heroImageType}.altText`,
											e.target.value
										);
									}}
									required
									error={altTextError}
								/>
							)}
						</>
					) : (
						<div>No hero image selected</div>
					)}
				</Grid>

				{portalId && !(heroImageOption === "doctor") && (
					<>
						<Grid item xs={12}>
							<h3 className={classes.uploadNewImageTitle}>Upload New Image</h3>
						</Grid>
						<Grid item xs={12}>
							<em>
								Your hero image must conform to Heartland's image standards and
								include alt text
							</em>
						</Grid>
						<Grid item xs={12}>
							<ImageUpload
								id="office-photo"
								onChange={handleUploadImage}
								isButton
								altText={""}
							/>
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default HeroImageSidebar;
