import React from "react";

import { makeStyles, Button } from "@material-ui/core";
import { Layout } from "../../components/Layout";
import { RouteComponentProps, NavLink } from "react-router-dom";
import {
	useTeamMembersQuery,
	TeamMember as TeamMemberType,
	useDeleteTeamMemberMutation,
	TeamMembersDocument,
} from "../../types/graphql-types";
import { TeamMember } from "./TeamMember";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import routes, { ProtectedTeamMemberRouteParams } from "../Routes";
import { mapGraphQlError } from "../../utils/errorHandler";
import ContentHeader from "../../components/ContentHeader";

const useStyles = makeStyles(() => ({
	seeAll: {
		textDecoration: "none",
	},
}));

export const AboutYourTeam: React.FC<
	RouteComponentProps<ProtectedTeamMemberRouteParams>
> = ({ match }) => {
	const classes = useStyles();
	const portalId = match?.params?.portalId?.toString() || "";
	const teamMemberId = match?.params?.teamMemberId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
	const [toggleAddTeamMember, setToggleAddTeamMember] = React.useState<boolean>(
		false
	);
	const { data, loading, error } = useTeamMembersQuery({
		variables: {
			portalId,
		},
	});

	const [
		teamMembers,
		setTeamMembers,
	] = React.useState<Array<TeamMemberType> | null>();

	const teamMembersResult = data?.teamMembers;
	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Team Members!",
			});
		}
		if (data) {
			setTeamMembers(data.teamMembers);
		} else {
			setTeamMembers(null);
		}
	}, [data, error, teamMembersResult, setSnackbarProps]);

	const [
		deleteTeamMemberMutation,
		deleteTeamMemberMutationResponse,
	] = useDeleteTeamMemberMutation({
		variables: {
			portalId,
			id: "",
		},
	});

	React.useEffect(() => {
		setSaveLoading(deleteTeamMemberMutationResponse.loading);
	}, [deleteTeamMemberMutationResponse]);

	const handleDeleteTeamMember = async (id: string) => {
		const result = await deleteTeamMemberMutation({
			variables: {
				portalId,
				id,
			},
			refetchQueries: [
				{
					query: TeamMembersDocument,
					variables: {
						portalId,
					},
				},
			],
			awaitRefetchQueries: true,
		});
		if (result?.data) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: true,
				message: "Team Member Deleted!",
			});
		} else {
			const error = result?.errors?.[0] && mapGraphQlError(result.errors[0]);
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: error?.displayableError || "Team Member Not Deleted!",
			});
		}
	};

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || saveLoading,
		});
	}, [loading, saveLoading, setLoadingIndicatorProps]);

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title="Team Members"
				button={
					<div>
						{teamMemberId === "" ? (
							<Button
								onClick={() => {
									setToggleAddTeamMember(!toggleAddTeamMember);
								}}
								color={toggleAddTeamMember ? "secondary" : "primary"}
								variant={toggleAddTeamMember ? "outlined" : "contained"}
							>
								{toggleAddTeamMember ? "cancel" : "add new team member"}
							</Button>
						) : (
							<NavLink
								to={routes.ABOUT_YOUR_TEAM.toPath({
									portalId,
								})}
								className={classes.seeAll}
							>
								<Button
									color={toggleAddTeamMember ? "secondary" : "primary"}
									variant={toggleAddTeamMember ? "outlined" : "contained"}
								>
									see all team members
								</Button>
							</NavLink>
						)}
					</div>
				}
				tooltipText="Team members can be displayed on your home page in the Meet the
        Team widget."
			/>

			{toggleAddTeamMember && (
				<TeamMember
					onSave={setSaveLoading}
					onDelete={handleDeleteTeamMember}
					member={{
						teamMemberId: "",
						firstName: "",
						middleName: "",
						lastName: "",
						designation: "",
						biography: "",
					}}
					isNew={true}
					portalId={portalId}
					setVisible={setToggleAddTeamMember}
				/>
			)}

			<div>
				{teamMemberId === "" ? (
					teamMembers?.map((member: TeamMemberType) => (
						<TeamMember
							key={member.teamMemberId}
							onDelete={handleDeleteTeamMember}
							onSave={setSaveLoading}
							member={member}
							isNew={false}
							portalId={portalId}
						/>
					))
				) : (
					<TeamMember
						key={
							teamMembers?.find(
								(member) => member.teamMemberId === teamMemberId
							)?.teamMemberId
						}
						onDelete={handleDeleteTeamMember}
						onSave={setSaveLoading}
						member={
							teamMembers?.find(
								(member) => member.teamMemberId === teamMemberId
							) || { teamMemberId: "" }
						}
						isNew={false}
						portalId={portalId}
					/>
				)}
			</div>
		</Layout>
	);
};
