import React from "react";

import {
	Doctor as DoctorType,
	useEditDoctorMutation,
	useUploadImageMutation,
} from "../../types/graphql-types";
import { ImageUpload } from "../../components/ImageUpload";
import { makeStyles, Typography, Divider, Theme } from "@material-ui/core";
import {
	RichTextEditor,
	serialize,
	deserialize,
	wordCountValidator,
} from "../../components/RichTextEditor";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { EditorState } from "draft-js";
import { mapGraphQlError } from "../../utils/errorHandler";
import headshotPlaceholder from "../../assets/images/headshot-placeholder.png";
import SaveButton from "../../components/SaveButton";
import { AspectRatios } from "../../components/ImageUpload/ImageUpload";
import { getHeightAndWidthFromDataUrl } from "../../utils/getHeightAndWidthFromDataUrl";

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		width: "100%",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	heading: {
		[theme.breakpoints.up(theme.customBreakpoints.md)]: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		height: 30,
		width: "100%",
		marginBottom: theme.spacing(2),
	},
	doctorInfo: {
		flex: 1,
		// TODO: There's got to be a better way to style this
		minWidth: "calc(100% - 230px)",
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			display: "none",
		},
		[theme.breakpoints.up("md")]: {
			maxWidth: "calc(100% - 230px)",
		},
	},
	image_upload: {
		flex: 1,
		marginRight: theme.spacing(2),
		marginBottom: theme.spacing(2),
	},
	editor: {
		marginBottom: theme.spacing(4),
		// this is to disable FMS editing per LS #567
		pointerEvents: "none",
		opacity: 0.5,
	},
	editor_title: {
		fontWeight: "bold",
	},
	savediv: {
		textAlign: "right",
	},
	divider: {
		marginBottom: theme.spacing(8),
		marginTop: theme.spacing(6),
	},
}));

interface DoctorProps {
	doctor: DoctorType;
	onSave: (saving: boolean) => void;
}

export const Doctor: React.FC<DoctorProps> = ({ doctor, onSave }) => {
	const classes = useStyles();
	const [file, setFile] = React.useState<File | null>(null);
	const [missionStatement, setMissionStatement] = React.useState<EditorState>(
		deserialize(doctor.bio?.missionStatement)
	);
	const [bio, setBio] = React.useState<EditorState>(
		deserialize(doctor.bio?.biography)
	);
	const [education, setEducation] = React.useState<EditorState>(
		deserialize(doctor.bio?.education)
	);
	const [
		professionalMemberships,
		setProfessionalMemberships,
	] = React.useState<EditorState>(
		deserialize(doctor.bio?.professionalMemberships)
	);
	const [
		continuingEducation,
		setContinuingEducation,
	] = React.useState<EditorState>(deserialize(doctor.bio?.continuingEducation));
	const [
		hobbiesAndInterests,
		setHobbiesAndInterests,
	] = React.useState<EditorState>(deserialize(doctor.bio?.hobbiesAndInterests));
	const { setSnackbarProps } = useGlobalSnackbar();

	const [editDoctorMutation, editDoctorMutationResult] = useEditDoctorMutation({
		variables: {
			npi: doctor.npi,
			doctor,
		},
	});

	const [uploadImageMutation, uploadImageResponse] = useUploadImageMutation({
		variables: {
			file: new File([], ""),
			publicId: "",
			tags: [],
		},
	});

	React.useEffect(() => {
		onSave(editDoctorMutationResult.loading || uploadImageResponse.loading);
	}, [editDoctorMutationResult, onSave, uploadImageResponse]);

	const missionStatementError = React.useMemo(() => {
		try {
			wordCountValidator(10).validateSync(missionStatement);
			return false;
		} catch (err) {
			return true;
		}
	}, [missionStatement]);

	const [imageError, setImageError] = React.useState(false);

	const handleImageChange = async (newImageFile: File) => {
		const fileAsDataURL = window.URL.createObjectURL(newImageFile);
		const dimensions = await getHeightAndWidthFromDataUrl(fileAsDataURL);

		if (dimensions.height >= 1280 && dimensions.width >= 960) {
			setImageError(false);
			setFile(newImageFile);
		} else {
			setImageError(true);
		}
	};

	const publicId = `doctorphotos/${doctor.npi}`;

	return (
		<div>
			<form id={`doctor_form_${doctor.npi}`} className={classes.form}>
				<div className={classes.image_upload}>
					<ImageUpload
						id={doctor.npi}
						publicId={publicId}
						width={200}
						aspectRatio={AspectRatios.PortraitStandard}
						image={doctor.headShot}
						onChange={handleImageChange}
						defaultImage={headshotPlaceholder}
						error={imageError}
					/>
				</div>
				<div className={classes.doctorInfo}>
					<div className={classes.heading}>
						<Typography variant="h6">{`${doctor.firstName || ""} ${
							doctor.middleName ? doctor.middleName + " " : ""
						}${doctor.lastName || ""}`}</Typography>
					</div>
					<div>
						<div className={classes.editor}>
							<Typography variant="subtitle1" className={classes.editor_title}>
								Mission Statement
							</Typography>
							<RichTextEditor
								value={missionStatement}
								onChange={setMissionStatement}
								error={missionStatementError}
								wordLimit={10}
							/>
						</div>
						<div className={classes.editor}>
							<Typography variant="subtitle1" className={classes.editor_title}>
								Biography
							</Typography>
							<RichTextEditor value={bio} onChange={setBio} />
						</div>
						<div className={classes.editor}>
							<Typography variant="subtitle1" className={classes.editor_title}>
								Education
							</Typography>
							<RichTextEditor value={education} onChange={setEducation} />
						</div>
						<div className={classes.editor}>
							<Typography variant="subtitle1" className={classes.editor_title}>
								Professional Memberships
							</Typography>
							<RichTextEditor
								value={professionalMemberships}
								onChange={setProfessionalMemberships}
							/>
						</div>
						<div className={classes.editor}>
							<Typography variant="subtitle1" className={classes.editor_title}>
								Continuing Education
							</Typography>
							<RichTextEditor
								value={continuingEducation}
								onChange={setContinuingEducation}
							/>
						</div>
						<div className={classes.editor}>
							<Typography variant="subtitle1" className={classes.editor_title}>
								Hobbies and Interests
							</Typography>
							<RichTextEditor
								value={hobbiesAndInterests}
								onChange={setHobbiesAndInterests}
							/>
						</div>
					</div>

					<div className={classes.savediv}>
						<SaveButton
							disabled={
								editDoctorMutationResult.loading ||
								uploadImageResponse.loading ||
								missionStatementError ||
								imageError
							}
							onClick={async () => {
								if (file) {
									const result = await uploadImageMutation({
										variables: {
											file,
											publicId,
											tags: [],
										},
									});
									if (result?.data) {
										setFile(null);
										setImageError(false);
										setSnackbarProps({
											autoHideDuration: 5000,
											open: true,
											success: true,
											message: "Doctor Image Saved!",
										});
									} else {
										const error =
											result?.errors?.[0] && mapGraphQlError(result.errors[0]);
										setSnackbarProps({
											autoHideDuration: 5000,
											open: true,
											success: true,
											message:
												error?.displayableError || "Doctor Image Not Saved!",
										});
									}
								}

								const result = await editDoctorMutation({
									variables: {
										npi: doctor.npi,
										doctor: {
											bio: {
												missionStatement: serialize(missionStatement),
												biography: serialize(bio),
												education: serialize(education),
												professionalMemberships: serialize(
													professionalMemberships
												),
												continuingEducation: serialize(continuingEducation),
												hobbiesAndInterests: serialize(hobbiesAndInterests),
											},
										},
									},
								});
								if (result?.data) {
									setSnackbarProps({
										autoHideDuration: 5000,
										open: true,
										success: true,
										message: "Doctor Saved!",
									});
								} else {
									const error =
										result?.errors?.[0] && mapGraphQlError(result.errors[0]);
									setSnackbarProps({
										autoHideDuration: 5000,
										open: true,
										success: false,
										message: error?.displayableError || "Doctor Not Saved!",
									});
								}
							}}
						/>
					</div>
				</div>
			</form>

			<Divider className={classes.divider} />
		</div>
	);
};
