import React from "react";
import { makeStyles, Theme, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { Service } from "../../types/graphql-types";
import { DraggableComponentProps } from "../../components/DraggableList";
import StarIcon from "@material-ui/icons/Star";

const useStyles = makeStyles((theme: Theme) => ({
	selectedService: {
		borderRadius: 20,
		marginLeft: theme.spacing(2),
		marginBottom: theme.spacing(2),
		paddingLeft: 8,
	},
	removeService: {
		fill: theme.color.white.main,
		marginRight: 8,
	},
	featuredIcon: {
		color: theme.color.orange.main,
		paddingRight: theme.spacing(0.5),
	},
}));

export interface SortableFeaturedServiceProps
	extends DraggableComponentProps<string> {
	services: Array<Service>;
	disableService: (taxonomyId: string) => void;
}

/**
 * This is not very DRY, but it's the best we could do on a time crunch:
 *
 * This component is a duplicate of the Widget Manager sortable list; we don't
 * use Formik state in this component, and also for reduced memory we are just
 * working with string values, instead of objects. So this is slightly
 * simplified.
 *
 * Changes made here should likely also be made in the other component until we
 * can create a more global version that combines the functionality of both.
 *
 * @see {src/routes/WidgetManager/Modals/FeaturedServices/SortableFeaturedService.tsx}
 */
export const SortableFeaturedService: React.FC<SortableFeaturedServiceProps> = ({
	services,
	item,
	provided,
	disableService,
}) => {
	const classes = useStyles();

	const serviceText = React.useMemo(() => {
		let retVal = "";

		services?.forEach((service) => {
			if (service.taxonomyId === item) {
				retVal = `${service.text} (summary)`;
			}
			service.children?.forEach((subService: Service) => {
				if (subService.taxonomyId === item) {
					retVal = `${service.text} > ${subService.text}`;
				}
			});
		});

		return retVal;
	}, [services, item]);

	return (
		<div ref={provided.innerRef} {...provided.draggableProps}>
			<span {...provided.dragHandleProps}>
				<DragIndicatorIcon />
			</span>
			<Button
				className={classes.selectedService}
				color="primary"
				variant="contained"
				onClick={() => disableService(item)}
			>
				<CloseIcon className={classes.removeService} />
				<StarIcon className={classes.featuredIcon} />
				{serviceText}
			</Button>
		</div>
	);
};
