import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import ContentHeader from "../../components/ContentHeader";
import SaveButton from "../../components/SaveButton";
import routes from "../Routes";
import {
	makeStyles,
	FormControlLabel,
	Radio,
	Switch,
	Theme,
	RadioGroup,
	Typography,
} from "@material-ui/core";
import ContentFooter from "../../components/ContentFooter";
import {
	OfficeMetadataInput,
	useEditOfficeMetadataMutation,
	usePracticeMetadataQuery,
} from "../../types/graphql-types";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { mapGraphQlError } from "../../utils/errorHandler";

const useStyles = makeStyles((theme: Theme) => ({
	formContainer: {
		display: "flex",
		flexDirection: "column",
	},
	radioGroup: {
		display: "flex",
		flexDirection: "row",
		flexWrap: "nowrap",
		marginTop: theme.spacing(4),
	},
	radioHeading: {
		textAlign: "center",
		marginBottom: theme.spacing(2),
	},
}));

interface MedicaidStatementOptionsFormProps {
	portalId: string;
}

export const MedicaidStatementOptionsForm = ({
	portalId,
}: MedicaidStatementOptionsFormProps) => {
	const classes = useStyles();

	const { data, loading, error } = usePracticeMetadataQuery({
		variables: {
			portalId,
		},
	});

	const officeMetadataResult = data?.officeMetadata;

	const [
		editOfficeMetadataMutation,
		editOfficeMetadataMutationResponse,
	] = useEditOfficeMetadataMutation();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();

	const initialValues: OfficeMetadataInput = React.useMemo(() => {
		return {
			hasMedicaidStatement: officeMetadataResult?.hasMedicaidStatement || false,
			medicaidStatementType:
				officeMetadataResult?.medicaidStatementType || "medicaid",
		};
	}, [
		officeMetadataResult?.hasMedicaidStatement,
		officeMetadataResult?.medicaidStatementType,
	]);

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Medicaid Statement Options!",
			});
		}
	}, [error, setSnackbarProps]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || editOfficeMetadataMutationResponse.loading,
		});
	}, [
		loading,
		editOfficeMetadataMutationResponse.loading,
		setLoadingIndicatorProps,
	]);

	if (!initialValues) return null;

	return (
		<Formik<OfficeMetadataInput>
			initialValues={initialValues}
			onSubmit={async (values) => {
				const editOfficeResult = await editOfficeMetadataMutation({
					variables: {
						portalId: portalId,
						officeMetadata: {
							hasMedicaidStatement: values.hasMedicaidStatement,
							medicaidStatementType: values.medicaidStatementType,
						},
					},
				});

				if (editOfficeResult?.data) {
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: true,
						message: "Medicaid Statement Options Saved!",
					});
				} else {
					const editOfficeResultError =
						editOfficeResult?.errors?.[0] &&
						mapGraphQlError(editOfficeResult.errors[0]);
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: false,
						message:
							editOfficeResultError?.displayableError ||
							"Medicaid Statement Options Not Saved!",
					});
				}
			}}
			enableReinitialize
		>
			{(formikBag) => {
				const canEditRadio = formikBag.values.hasMedicaidStatement;
				return (
					<>
						<ContentHeader
							title={routes.MEDICAID_STATEMENT_OPTIONS.title}
							button={
								<SaveButton
									form="medicaid-statement-options-form"
									disabled={!formikBag.isValid}
								/>
							}
						/>
						<Form
							id="medicaid-statement-options-form"
							className={classes.formContainer}
						>
							<Field name="hasMedicaidStatement">
								{({ field }: FieldProps) => (
									<FormControlLabel
										aria-label={"Enable Medicaid Statement"}
										label={"Enable Medicaid Statement"}
										control={<Switch {...field} checked={field.value} />}
									/>
								)}
							</Field>
							<Field name="medicaidStatementType">
								{({ field }: FieldProps) => (
									<RadioGroup className={classes.radioGroup} {...field}>
										<FormControlLabel
											disabled={!canEditRadio}
											key={"medicaid-option-0"}
											value={"medicaid"}
											control={<Radio />}
											labelPlacement="bottom"
											label={
												<div>
													<Typography
														variant="h6"
														className={classes.radioHeading}
													>
														Option A (default)
													</Typography>
													<span>
														<b>
															"Please note that we do not accept state funded
															Medicaid plans.
														</b>
													</span>{" "}
													<span>
														For questions regarding Medicare and other insurance
														plans, please contact the office to discuss
														eligibility and coverage options."
													</span>
												</div>
											}
										/>
										<FormControlLabel
											disabled={!canEditRadio}
											key={"medicaid-option-1"}
											value={"medicare_medicaid"}
											control={<Radio />}
											labelPlacement="bottom"
											label={
												<div>
													<Typography
														variant="h6"
														className={classes.radioHeading}
													>
														Option B
													</Typography>
													<span>
														<b>
															"Please note that we do not accept Medicare or
															state funded Medicaid plans.
														</b>
													</span>{" "}
													<span>
														For questions regarding Medicare and other insurance
														plans, please contact the office to discuss
														eligibility and coverage options."
													</span>
												</div>
											}
										/>
									</RadioGroup>
								)}
							</Field>
						</Form>
						<ContentFooter>
							<SaveButton
								form="medicaid-statement-options-form"
								disabled={!formikBag.isValid}
							/>
						</ContentFooter>
					</>
				);
			}}
		</Formik>
	);
};
