import React from "react";

import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";

import {
	makeStyles,
	Card,
	CardContent,
	CardActions,
	IconButton,
	CardHeader,
	Theme,
} from "@material-ui/core";
import { WidgetImageMapper } from "./WidgetImageMapper";
import { HomeOptionsWidget, Service } from "../../types/graphql-types";
import { useFormikContext } from "formik";
import { WidgetManagerModal } from "./Modals/WidgetManagerModal";
import { formatWidgetName } from "./utils";
import { WidgetNames } from "./WidgetNames";
import { UseGlobalDarkModeContext } from "../GlobalDarkMode";

interface ThemeProps {
	darkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	basic_card: {},
	card: {
		cursor: "pointer",
		"&:hover": {
			backgroundColor: theme.color.grey.light,
		},
	},
	card_disabled: {
		color: theme.color.white.main,
		backgroundColor: theme.color.grey.main,
	},
	cardHeader: {
		"& > div > span": {
			fontSize: "1rem !important",
		},
		paddingBottom: 0,

		[theme.breakpoints.up("md")]: {
			padding: 16,
		},
	},
	content: {
		textAlign: "center",
		paddingBottom: 0,
		paddingTop: 0,

		[theme.breakpoints.up("md")]: {
			paddingTop: theme.spacing(2),
		},
	},
	header: {
		fontSize: theme.spacing(2),
		textAlign: "left",
	},
	header_disabled: {
		fontSize: theme.spacing(2),
		fontWeight: "bold",
		textAlign: "left",
	},
	img_container: {
		alignItems: "center",
		display: "flex",
		height: 90,
	},
	img: ({ darkMode }: ThemeProps) => ({
		width: "100%",
		backgroundColor: darkMode ? theme.color.grey.main : theme.color.white.main,
	}),
	icon: {
		color: theme.color.white.main,
		fontWeight: "bold",
		fontSize: 24,
		stroke: theme.color.white.main,
	},
	actions: {
		display: "block",
		textAlign: "center",
	},
	remove: {
		backgroundColor: theme.color.red.main,
		marginTop: "10px",
		marginBottom: "10px",

		"&:hover": {
			backgroundColor: theme.color.red.dark,
		},
	},
	add: {
		backgroundColor: theme.color.green.main,
		marginTop: "10px",
		marginBottom: "10px",

		"&:hover": {
			backgroundColor: theme.color.green.dark,
		},
	},
}));

interface WidgetProps {
	disabled?: boolean;
	widget: HomeOptionsWidget;
	services?: Array<Service>;
	index: number;
}

export const Widget: React.FC<WidgetProps> = ({
	disabled,
	widget,
	services,
	index,
}) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);
	const [addRemoveHover, setAddRemoveHover] = React.useState<boolean>(false);
	const isDisabled = !widget.enabled && (disabled || false);
	const hasExtendedOptions =
		widget.name === WidgetNames.google_block ||
		widget.name === WidgetNames.invisalign ||
		widget.name === WidgetNames.mission_statement ||
		widget.name === WidgetNames.featured_services ||
		widget.name === WidgetNames.esteem;

	const form = useFormikContext();

	return (
		<div>
			<Card
				className={
					isDisabled
						? classes.card_disabled
						: hasExtendedOptions && !addRemoveHover
						? classes.card
						: classes.basic_card
				}
				onClick={() => {
					if (!isDisabled) {
						form.setFieldValue(`widgets.${index}.enabled`, true);
						setModalOpen(true);
					}
				}}
			>
				<CardHeader
					title={
						widget.name === WidgetNames.mission_statement
							? "Office Motto"
							: formatWidgetName(widget.name)
					}
					className={classes.cardHeader}
				/>
				<CardContent className={classes.content}>
					<div className={classes.img_container}>
						<div>
							<img
								src={WidgetImageMapper(widget)}
								alt=""
								className={classes.img}
							/>
						</div>
					</div>
				</CardContent>
				{!isDisabled && (
					<CardActions className={classes.actions}>
						{widget.enabled ? (
							<IconButton
								className={classes.remove}
								onMouseEnter={() => setAddRemoveHover(true)}
								onMouseLeave={() => setAddRemoveHover(false)}
								onClick={(
									e: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									e.stopPropagation();
									// Toggle enabled to false
									form.setFieldValue(`widgets.${index}.enabled`, false);
								}}
							>
								<RemoveIcon className={classes.icon} />
							</IconButton>
						) : (
							<IconButton
								className={classes.add}
								onMouseEnter={() => setAddRemoveHover(true)}
								onMouseLeave={() => setAddRemoveHover(false)}
								onClick={(
									e: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									e.stopPropagation();
									// Toggle enabled to true AND open dialog/modal window
									form.setFieldValue(`widgets.${index}.enabled`, true);
									if (hasExtendedOptions) setModalOpen(true);
								}}
							>
								<AddIcon className={classes.icon} />
							</IconButton>
						)}
					</CardActions>
				)}
			</Card>
			{hasExtendedOptions && (
				<WidgetManagerModal
					open={modalOpen}
					setOpen={setModalOpen}
					widget={widget}
					services={services}
					index={index}
				/>
			)}
		</div>
	);
};
