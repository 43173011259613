import React from "react";
import clsx from "clsx";
import { Button, makeStyles, Theme } from "@material-ui/core";
import { UseGlobalDarkModeContext } from "../GlobalDarkMode";

interface ThemeProps {
	darkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	save: ({ darkMode }: ThemeProps) => ({
		backgroundColor: theme.color.yellow.main,
		color: darkMode ? theme.color.white.main : theme.color.black.main,
		padding: theme.spacing(0.25, 3),
		"&:hover": {
			backgroundColor: theme.color.orange.light,
		},
	}),
}));

interface SaveButtonProps {
	disabled?: boolean;
	form?: string;
	label?: string;
	onClick?: (e: React.MouseEvent) => void;
	className?: string;
}
const SaveButton: React.FC<SaveButtonProps> = ({
	disabled = false,
	form,
	label,
	onClick,
	className,
}) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });
	return (
		<Button
			className={clsx(classes.save, className)}
			disabled={disabled}
			type="submit"
			form={form}
			color="primary"
			variant="contained"
			onClick={onClick}
		>
			{label ? label : "save"}
		</Button>
	);
};

export default SaveButton;
