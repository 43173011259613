import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Divider,
	FormControlLabel,
	makeStyles,
	Paper,
	Radio,
	RadioGroup,
	Theme,
	Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Field, FieldProps, Form, Formik } from "formik";
import React, { ChangeEvent } from "react";
import ContentFooter from "../../components/ContentFooter";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import SaveButton from "../../components/SaveButton";
import {
	HomeOptionsDocument,
	HomeOptionsInput,
	Templates,
	useHomeOptionsQuery,
	useSetHomeOptionsMutation,
	useUpdateAltTextMutation,
	WelcomeBannerWidgetFragment,
	GetImagesByTagDocument,
	GetImagesByTagQuery,
	GetImagesByTagQueryVariables,
	HeroImageTypes,
} from "../../types/graphql-types";
import { mapGraphQlError } from "../../utils/errorHandler";
import AcadiaImage from "../../assets/images/acadia.png";
import BiscayneImage from "../../assets/images/biscayne.png";
import CongareeImage from "../../assets/images/congaree.png";
import DenaliImage from "../../assets/images/denali.png";
import EvergladesImage from "../../assets/images/everglades.png";
import EsteemImage from "../../assets/images/esteem.png";
import { ImageModal } from "./ImageModal/ImageModal";
import { Modal } from "@material-ui/core";
import removeTypename from "../../utils/removeTypename";
import cloudinaryUtil from "../../utils/cloudinaryUtil";
import { WidgetNames } from "../../components/WidgetManager/WidgetNames";
import { UseGlobalDarkModeContext } from "../../components/GlobalDarkMode";

interface ThemeProps {
	darkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	infoContainer: {
		display: "flex",
		justifyContent: "space-around",
		alignItems: "baseline",
		margin: "auto auto 2rem 1rem",
		textShadow: `4px 1px 8px ${theme.color.grey.light}`,
	},
	infoItem: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-around",
		alignItems: "center",
		margin: "0.5rem auto 0.25rem auto",
	},
	previewImage: {
		width: 100,
		marginTop: "0.5rem",
	},
	selectImage: ({ darkMode }: ThemeProps) => ({
		backgroundColor: theme.color.green.main,
		color: darkMode ? theme.color.black.main : theme.color.white.main,
		padding: "2px 24px",
		position: "unset",
		display: "block",
		marginTop: "8px",

		"&:hover": {
			backgroundColor: theme.color.green.light,
		},
	}),
	detailsContainer: {
		display: "flex",
		flexDirection: "column",
	},
	group: {
		paddingLeft: theme.spacing(2),
	},
	radio: {
		marginBottom: theme.spacing(3),
	},
	radioLabel: {
		display: "flex",
		marginLeft: theme.spacing(2),
	},
	radioImage: {
		width: 150,
	},
	radioTextArea: {
		marginLeft: theme.spacing(2),
	},
	radioTextHead: {
		marginBottom: theme.spacing(0.5),
	},
	description: {
		fontSize: "0.75rem",
	},
	imageContainer: {
		height: theme.spacing(20),
		width: theme.spacing(30),
		position: "relative",
	},
	imageWrapper: {
		position: "absolute",
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		height: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"& img": {
			width: "auto",
			height: "auto",
			maxHeight: "90%",
			maxWidth: "90%",
		},
	},
	promoDetails: {
		display: "flex",
		flexDirection: "column",
	},
	promoText: {
		marginBottom: "2rem",
	},
	denali_inputs: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
	},
}));

const templateMappings = {
	[Templates.Atlanta]: {
		imgSrc: EvergladesImage,
		label: "Atlanta",
		description:
			"This layout is professional, calming, welcoming, and office-focused. It is equally focused on both the Doctor and team, with dedicated sections that feature a brief statement and photos so patients can 'meet the team' and get a sense of your office’s personality.",
	},
	[Templates.Boston]: {
		imgSrc: EvergladesImage,
		label: "Boston",
		description:
			"This layout is light, airy, and engaging. Not to mention, it is Doctor focused, with a personalized Doctor photo at the top of the page, as well as a section in the middle of the page featuring your Dentistry mission statement and Doctor photo.",
	},
	[Templates.Chicago]: {
		imgSrc: EvergladesImage,
		label: "Chicago",
		description:
			"This layout is modern, vibrant, and fresh, with quirky, geometric buttons and bold font colors to showcase your office's fun personality. It is patient-focused, with a customizable patient banner image that showcases your phenomenal work.",
	},
	[Templates.Dallas]: {
		imgSrc: EvergladesImage,
		label: "Dallas",
		description:
			"This layout is bright, fresh, and clean, with a dark, translucent banner image and bold pops of contrasting white, accented by your primary theme color. This layout provides important information to the patient immediately (office hours, a 'make an appointment' button, live chat, business hours, and Google reviews right at the top of the page).",
	},
	[Templates.Eugene]: {
		imgSrc: EvergladesImage,
		label: "Eugene",
		description:
			"This layout is professional, yet bold and fun, with primary theme colors on full display, and secondary theme colored accents throughout the page. If you'd like to highlight the fact that you offer Teledentistry and are an Invisalign certified provider, this layout is perfect for you.",
	},
	[Templates.Acadia]: {
		imgSrc: AcadiaImage,
		label: "Acadia",
		description: "Displays a large hero image.",
	},
	[Templates.Biscayne]: {
		imgSrc: BiscayneImage,
		label: "Biscayne (default)",
		description:
			"White background with doctor's mission statement and office photo. This is the default template.",
	},
	[Templates.Congaree]: {
		imgSrc: CongareeImage,
		label: "Congaree",
		description: "Brand color background with doctor's mission statement.",
	},
	[Templates.Denali]: {
		imgSrc: DenaliImage,
		label: "Denali",
		description:
			"Hero image with customized greeting and additional call to action for teledentistry.",
	},
	[Templates.Everglades]: {
		imgSrc: EvergladesImage,
		label: "Everglades",
		description: "Doctor's photo with custom introductory message.",
	},
	[Templates.Esteem]: {
		imgSrc: EsteemImage,
		label: "Esteem",
		description: "Esteem welcome banner with banner image and description.",
	},
};

enum ModernTemplates {
	Atlanta = "atlanta",
	Boston = "boston",
	Chicago = "chicago",
	Dallas = "dallas",
	Eugene = "eugene",
}

enum LegacyTemplates {
	Acadia = "acadia",
	Biscayne = "biscayne",
	Congaree = "congaree",
	Denali = "denali",
	Everglades = "everglades",
}

enum PromotionalTemplates {
	Esteem = "esteem",
}

interface TemplateFormProps {
	portalId: string;
}

export const TemplateForm: React.FC<TemplateFormProps> = ({ portalId }) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const [modalOpen, setModalOpen] = React.useState(false);
	const { data, loading, error } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});

	const [expanded, setExpanded] = React.useState("");

	const handleChange = (type: string) => (
		event: ChangeEvent<{}>,
		isExpanded: boolean
	) => {
		setExpanded(isExpanded ? type : "");
	};

	React.useEffect(() => {
		setExpanded(
			data?.homeOptions?.template
				? Object.values(LegacyTemplates).includes(
						(data?.homeOptions?.template as unknown) as LegacyTemplates
				  )
					? "legacy"
					: Object.values(PromotionalTemplates).includes(
							(data?.homeOptions?.template as unknown) as PromotionalTemplates
					  )
					? "promotional"
					: "modern"
				: ""
		);
	}, [data?.homeOptions?.template]);

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Welcome Banner!",
			});
		}
	}, [error, setSnackbarProps]);

	const [
		setHomeOptionsMutation,
		setHomeOptionsMutationResponse,
	] = useSetHomeOptionsMutation();

	const [updateAltText] = useUpdateAltTextMutation();

	const homeOptionsResult = data?.homeOptions;
	const initialValues = removeTypename(homeOptionsResult);
	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || setHomeOptionsMutationResponse.loading,
		});
	}, [
		loading,
		setHomeOptionsMutationResponse.loading,
		setLoadingIndicatorProps,
	]);

	const welcomeBannerWidgetIndex = React.useMemo(
		() =>
			homeOptionsResult?.widgets.findIndex(
				({ name }) => name === WidgetNames.welcome_banner
			) ?? -1,
		[homeOptionsResult]
	);

	const savedWelcomeBanner = homeOptionsResult?.widgets[
		welcomeBannerWidgetIndex
	] as WelcomeBannerWidgetFragment;

	if (!initialValues) return null;
	return (
		<>
			<Paper className={classes.infoContainer} elevation={2}>
				<div className={classes.infoItem}>
					<Typography variant="h4">Banner Type: </Typography>
					<Typography variant="h6">
						<i>
							{(data?.homeOptions?.template.charAt(0).toUpperCase() as string) +
								data?.homeOptions?.template.slice(1)}
						</i>
					</Typography>
					<img
						src={templateMappings[data?.homeOptions?.template as string].imgSrc}
						alt={data?.homeOptions?.template}
						className={classes.previewImage}
					/>
				</div>
				<Divider orientation="vertical" flexItem />
				<div className={classes.infoItem}>
					<Typography variant="h4">Selected Image: </Typography>{" "}
					{Templates[data?.homeOptions?.template as string] !==
						Templates.Congaree &&
					savedWelcomeBanner.heroImage?.type &&
					savedWelcomeBanner.heroImage?.[savedWelcomeBanner.heroImage.type]
						?.publicId ? (
						<div className={classes.imageContainer}>
							<div className={classes.imageWrapper}>
								<img
									id={`image_${
										savedWelcomeBanner.heroImage[
											savedWelcomeBanner.heroImage.type
										]?.publicId
									}`}
									src={cloudinaryUtil.imageSrc({
										publicId:
											savedWelcomeBanner.heroImage[
												savedWelcomeBanner.heroImage.type
											]?.publicId ?? "",
									})}
									alt={`${
										savedWelcomeBanner.heroImage[
											savedWelcomeBanner.heroImage.type
										]?.altText
									}`}
								/>
							</div>
						</div>
					) : (
						<Typography>No image to display</Typography>
					)}
				</div>
			</Paper>
			<Formik<HomeOptionsInput>
				initialValues={initialValues}
				onSubmit={async (homeOptions: HomeOptionsInput) => {
					const result = await setHomeOptionsMutation({
						variables: {
							portalId,
							homeOptions,
						},
						refetchQueries: [
							{
								query: HomeOptionsDocument,
								variables: {
									portalId: portalId,
								},
							},
						],
						awaitRefetchQueries: true,
					});
					if (result?.data) {
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: true,
							message: "Homepage Template Saved!",
						});
					} else {
						const error =
							result?.errors?.[0] && mapGraphQlError(result.errors[0]);
						setSnackbarProps({
							autoHideDuration: 5000,
							open: true,
							success: false,
							message:
								error?.displayableError || "Homepage Template Not Saved!",
						});
					}
					// Only refetch the cloudinary resource if the alt text has changed and it is a user uploaded image
					const heroImageValue =
						homeOptions.widgets[welcomeBannerWidgetIndex].heroImage?.[
							`${homeOptions.widgets[welcomeBannerWidgetIndex]?.heroImage?.type}`
						];
					const savedHeroImage =
						savedWelcomeBanner?.heroImage?.[
							`${homeOptions?.widgets[welcomeBannerWidgetIndex]?.heroImage?.type}`
						];

					const heroImageType =
						homeOptions.template === "everglades"
							? HeroImageTypes.Doctor
							: HeroImageTypes.Upload;
					const tag =
						heroImageType === HeroImageTypes.Doctor ? "everglades" : "hero";
					if (
						(heroImageValue?.altText !== savedHeroImage?.altText &&
							heroImageValue.publicId?.includes(`${tag}_`)) ||
						(heroImageValue?.altText !== savedHeroImage?.altText &&
							tag === "everglades")
					) {
						updateAltText({
							variables: {
								publicId: heroImageValue?.publicId as string,
								altText: heroImageValue?.altText as string,
							},
							update: (proxy) => {
								// Read the data from our cache for this query.
								const readQueryData = proxy.readQuery<
									GetImagesByTagQuery,
									GetImagesByTagQueryVariables
								>({
									query: GetImagesByTagDocument,
									variables: {
										tag: `${portalId}_${tag}`,
									},
								});
								// Write our data back to the cache with the new image in it
								if (readQueryData?.getImagesByTag) {
									proxy.writeQuery<
										GetImagesByTagQuery,
										GetImagesByTagQueryVariables
									>({
										query: GetImagesByTagDocument,
										variables: {
											tag: `${portalId}_${tag}`,
										},
										data: {
											__typename: "Query",
											getImagesByTag: readQueryData.getImagesByTag.map(
												(image) =>
													image.publicId === heroImageValue?.publicId
														? {
																...image,
																altText: heroImageValue?.altText,
														  }
														: image
											),
										},
									});
								}
							},
						});
					}
				}}
				enableReinitialize
			>
				{(formikBag) => (
					<Form id="homepage-template-form">
						<Field name="template">
							{({ field }: FieldProps) => (
								<RadioGroup className={classes.group} {...field}>
									{Object.keys(ModernTemplates).map((template: string) => (
										<FormControlLabel
											key={template}
											value={Templates[template]}
											control={<Radio />}
											className={classes.radio}
											label={
												<div
													style={{
														display: "flex",
														justifyContent: "space-around",
													}}
												>
													<div className={classes.radioLabel}>
														<div>
															<img
																src={
																	templateMappings[Templates[template]].imgSrc
																}
																alt={template}
																className={classes.radioImage}
															/>
														</div>
														<div className={classes.radioTextArea}>
															<h5 className={classes.radioTextHead}>
																{templateMappings[Templates[template]].label}
															</h5>
															<div className={classes.description}>
																<em>
																	{
																		templateMappings[Templates[template]]
																			.description
																	}
																</em>
															</div>
															{Templates[template] !== Templates.Eugene && (
																<div>
																	<Button
																		type="button"
																		form="homepage-template-form"
																		className={classes.selectImage}
																		onClick={() => setModalOpen(true)}
																		disabled={
																			formikBag.values.template !==
																			Templates[template]
																		}
																	>
																		Select
																		{
																			Templates[template] ===
																				Templates.Boston && " Featured Doctor" //there's a space at the beginning of this on purpose
																		}
																		/Upload Image
																	</Button>
																</div>
															)}
														</div>
													</div>
												</div>
											}
										/>
									))}
									<Accordion
										expanded={expanded === "legacy"}
										onChange={handleChange("legacy")}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											Legacy
										</AccordionSummary>
										<AccordionDetails className={classes.detailsContainer}>
											{Object.keys(LegacyTemplates).map((template: string) => (
												<FormControlLabel
													key={template}
													value={Templates[template]}
													control={<Radio />}
													className={classes.radio}
													label={
														<div
															style={{
																display: "flex",
																justifyContent: "space-around",
															}}
														>
															<div className={classes.radioLabel}>
																<div>
																	<img
																		src={
																			templateMappings[Templates[template]]
																				.imgSrc
																		}
																		alt={template}
																		className={classes.radioImage}
																	/>
																</div>
																<div className={classes.radioTextArea}>
																	<h5 className={classes.radioTextHead}>
																		{
																			templateMappings[Templates[template]]
																				.label
																		}
																	</h5>
																	<div className={classes.description}>
																		<em>
																			{
																				templateMappings[Templates[template]]
																					.description
																			}
																		</em>
																	</div>
																	{Templates[template] === Templates.Acadia && (
																		<Button
																			type="button"
																			form="homepage-template-form"
																			className={classes.selectImage}
																			onClick={() => setModalOpen(true)}
																			disabled={
																				formikBag.values.template !==
																				Templates.Acadia
																			}
																		>
																			Select Image
																		</Button>
																	)}
																	{Templates[template] ===
																		Templates.Biscayne && (
																		<div>
																			<Button
																				type="button"
																				form="homepage-template-form"
																				className={classes.selectImage}
																				onClick={() => setModalOpen(true)}
																				disabled={
																					formikBag.values.template !==
																					Templates.Biscayne
																				}
																			>
																				Select/Upload Image
																			</Button>
																		</div>
																	)}
																	{Templates[template] === Templates.Denali && (
																		<div className={classes.denali_inputs}>
																			<Button
																				type="button"
																				form="homepage-template-form"
																				className={classes.selectImage}
																				onClick={() => setModalOpen(true)}
																				disabled={
																					formikBag.values.template !==
																					Templates.Denali
																				}
																			>
																				Select Image
																			</Button>
																		</div>
																	)}
																	{Templates[template] ===
																		Templates.Everglades && (
																		<div>
																			<Button
																				type="button"
																				form="homepage-template-form"
																				className={classes.selectImage}
																				onClick={() => setModalOpen(true)}
																				disabled={
																					formikBag.values.template !==
																					Templates.Everglades
																				}
																			>
																				Select Featured Doctor/Upload Image
																			</Button>
																		</div>
																	)}
																</div>
															</div>
														</div>
													}
												/>
											))}
										</AccordionDetails>
									</Accordion>
									<Accordion
										expanded={expanded === "promotional"}
										onChange={handleChange("promotional")}
									>
										<AccordionSummary expandIcon={<ExpandMoreIcon />}>
											Promotional
										</AccordionSummary>
										<AccordionDetails className={classes.promoDetails}>
											<p className={classes.promoText}>
												<i>
													Showcase that you're a [Product Name - ex: Invisalign,
													Teeth Tomorrow, etc] provider and choose from the
													selections below to display particular products.
												</i>
											</p>
											{Object.keys(PromotionalTemplates).map((template) => (
												<FormControlLabel
													key={template}
													value={Templates[template]}
													control={<Radio />}
													className={classes.radio}
													label={
														<div
															style={{
																display: "flex",
																justifyContent: "space-around",
															}}
														>
															<div className={classes.radioLabel}>
																<div>
																	<img
																		src={
																			templateMappings[Templates[template]]
																				.imgSrc
																		}
																		alt={template}
																		className={classes.radioImage}
																	/>
																</div>
																<div className={classes.radioTextArea}>
																	<h5 className={classes.radioTextHead}>
																		{
																			templateMappings[Templates[template]]
																				.label
																		}
																	</h5>
																	<div className={classes.description}>
																		<em>
																			{
																				templateMappings[Templates[template]]
																					.description
																			}
																		</em>
																	</div>
																</div>
															</div>
														</div>
													}
												/>
											))}
										</AccordionDetails>
									</Accordion>
								</RadioGroup>
							)}
						</Field>
						<Modal open={modalOpen}>
							<ImageModal
								open={modalOpen}
								portalId={portalId}
								setOpen={setModalOpen}
								formikBag={formikBag}
								welcomeBannerWidgetIndex={welcomeBannerWidgetIndex}
							/>
						</Modal>
					</Form>
				)}
			</Formik>
			<ContentFooter>
				<SaveButton form="homepage-template-form" />
			</ContentFooter>
		</>
	);
};
