import React from "react";
import clsx from "clsx";
import { ContentState } from "draft-js";
import { makeStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core";
import { getCharCount, getWordCount, getLineCount } from "./dataUtils";

const useStyles = makeStyles((theme: Theme) => ({
	counterWrapper: {
		width: "100%",
		textAlign: "right",
	},
	counterCloseToLimit: {
		color: theme.color.orange.main,
	},
	counterOverLimit: {
		color: theme.palette.error.dark,
	},
}));

export enum CounterType {
	Char,
	Word,
	Line,
}

interface CounterProps {
	contentState: ContentState;
	type: CounterType;
	limit?: number;
}

export const Counter: React.FC<CounterProps> = ({
	contentState,
	type,
	limit,
}) => {
	const classes = useStyles();

	const count = React.useMemo(() => {
		switch (type) {
			case CounterType.Char:
				return getCharCount(contentState);
			case CounterType.Word:
				return getWordCount(contentState);
			case CounterType.Line:
				return getLineCount(contentState);
		}
	}, [contentState, type]);

	const getTypeText = React.useCallback(
		(c: number) => {
			switch (type) {
				case CounterType.Char:
					return c === 1 ? "character" : "characters";
				case CounterType.Word:
					return c === 1 ? "word" : "words";
				case CounterType.Line:
					return c === 1 ? "line" : "lines";
			}
		},
		[type]
	);

	if (!limit) {
		return null;
	} else if (limit === Infinity || limit === -Infinity || isNaN(limit)) {
		return (
			<div className={classes.counterWrapper}>
				{count} {getTypeText(count)}
			</div>
		);
	} else {
		const remaining = limit - count;
		return (
			<div
				className={clsx(
					classes.counterWrapper,
					count >= limit - 2 && count <= limit && classes.counterCloseToLimit,
					count > limit && classes.counterOverLimit
				)}
			>
				{remaining >= 0 ? (
					<>
						{remaining} {getTypeText(remaining)} remaining
					</>
				) : (
					<>
						{Math.abs(remaining)} {getTypeText(Math.abs(remaining))} over limit
					</>
				)}
			</div>
		);
	}
};
