import React from "react";
import { FormikProps } from "formik";
import { TeamMemberAssignmentInput } from "./TeamArrangement";
import {
	TeamMember,
	TeamMembersQueryHookResult,
	ArrangeTeamMembersMutationFn,
	useTeamMembersQuery,
} from "../../types/graphql-types";
import { makeStyles, Theme } from "@material-ui/core";
import routes from "../Routes";
import { DraggableList } from "../../components/DraggableList";
import { SortableTeamMember } from "./SortableTeamMember";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import ContentFooter from "../../components/ContentFooter";

const useStyles = makeStyles((theme: Theme) => ({
	content_title: {
		display: "inline-block",
		flex: 1,
		fontSize: theme.typography.fontSizeContentTitle,
		fontWeight: theme.typography.fontWeightBold,
		marginBottom: theme.spacing(5),
	},
	search: {
		alignItems: "center",
		backgroundColor: theme.color.grey.light,
		boxShadow: theme.shadows[1],
		borderRadius: 4,
		display: "flex",
		padding: theme.spacing(1.5, 1.5, 1.5, 0),
		marginBottom: theme.spacing(4),
		position: "relative",
	},
	search_input: {
		width: "100%",
	},
	adornment: {
		margin: theme.spacing(0, 1),
	},
	search_icon: {
		color: theme.color.grey.main,
		cursor: "pointer",
	},
	dropDown: {
		borderRadius: theme.shape.borderRadius,
		position: "absolute",
		top: 41,
		zIndex: 200,
		boxSizing: "border-box",
		maxWidth: theme.centralBlockMaxWidth,
		width: "100%",
		minWidth: "100%",
		backgroundColor: theme.palette.grey[100],
		boxShadow: theme.shadows[1],
		listStyleType: "none",
		padding: `0px ${theme.spacing(3)}px`,
	},
	row: {
		alignItems: "center",
		borderBottom: `1px solid ${theme.color.grey.dark}`,
		cursor: "pointer",
		display: "flex",
		padding: theme.spacing(1),

		"&:last-child": {
			border: "none",
		},

		"&:hover": {
			backgroundColor: theme.palette.grey[200],
		},
	},
	teamMember_name: {
		flex: 1,
	},
	teamMember_npi: {
		flex: 1,
	},
	teamMember_add: {
		lineHeight: 0,
	},

	flex_col: {
		flex: 1,
	},
	drag_icon: {
		color: theme.color.grey.dark,
		cursor: "move",
		marginRight: theme.spacing(2),
	},
	remove_icon: {
		color: theme.color.grey.dark,
		cursor: "pointer",
		marginLeft: theme.spacing(2),
	},
}));

interface TeamMemberAssignmentBodyProps
	extends FormikProps<TeamMemberAssignmentInput> {
	portalId: string;
	teamMemberAssignment: TeamMembersQueryHookResult;
	arrangeTeamMembers: ArrangeTeamMembersMutationFn;
}

export const TeamMemberAssignmentBody: React.FC<TeamMemberAssignmentBodyProps> = ({
	portalId,
	teamMemberAssignment,
	arrangeTeamMembers,
	...formik
}) => {
	const classes = useStyles();
	const { setSnackbarProps } = useGlobalSnackbar();
	const [searchQuery] = React.useState<string>("");

	const { error } = useTeamMembersQuery({
		variables: {
			portalId: portalId,
		},
		skip: !searchQuery,
	});

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch TeamMembers!",
			});
		}
	}, [error, setSnackbarProps]);

	const handleSort = (sortedList: Array<TeamMember>) => {
		formik.setFieldValue("teamMembers", [
			...sortedList,
			...formik.values.teamMembers.filter(
				({ teamMemberId }: { teamMemberId: string }) =>
					!sortedList
						.map((item: TeamMember) => item.teamMemberId)
						.includes(teamMemberId)
			),
		]);
	};

	return (
		<>
			<form id="teamMember-assignment-form" onSubmit={formik.handleSubmit}>
				<ContentHeader
					title={routes.TEAM_ARRANGEMENT.title}
					button={<SaveButton form="teamMember-assignment-form" />}
					tooltipText="Team members can be sorted on this screen. The order displayed here
          is the order the team members will be listed in the About Your
          Team page."
				/>
				{teamMemberAssignment.error && (
					<div>Error: {JSON.stringify(teamMemberAssignment.error)}</div>
				)}
				<div>
					<div className={classes.content_title}>Arrange Team Members</div>
					<div>
						{!teamMemberAssignment.loading && (
							<DraggableList<{}, TeamMember>
								droppableId="teamMember-assignment"
								listItems={formik.values.teamMembers.map((teamMember) => ({
									draggable: true,
									item: teamMember,
								}))}
								setListItems={handleSort}
								component={SortableTeamMember}
							/>
						)}
					</div>
				</div>
			</form>
			<ContentFooter>
				<SaveButton form="teamMember-assignment-form" />
			</ContentFooter>
		</>
	);
};
