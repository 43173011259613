import React from "react";

import {
	TeamMember as TeamMemberType,
	useUploadImageMutation,
	useEditTeamMemberMutation,
	TeamMemberInput,
	TeamMembersDocument,
	useAddTeamMemberMutation,
} from "../../types/graphql-types";
import { ImageUpload } from "../../components/ImageUpload";
import {
	makeStyles,
	Divider,
	Typography,
	Theme,
	Button,
} from "@material-ui/core";
import { TextFieldAdapter } from "../../components/TextField";
import {
	RichTextEditor,
	deserialize,
	serialize,
} from "../../components/RichTextEditor";
import cloudinaryUtil from "../../utils/cloudinaryUtil";
import { Formik, Form, Field } from "formik";
import { RemoveTeamMemberDialog } from "./RemoveTeamMemberDialog";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { EditorState } from "draft-js";
import { formatTeamMemberName } from "../../utils/formatName";
import { mapGraphQlError } from "../../utils/errorHandler";
import headshotPlaceholder from "../../assets/images/headshot-placeholder.png";
import SaveButton from "../../components/SaveButton";
import { AspectRatios } from "../../components/ImageUpload/ImageUpload";

const useStyles = makeStyles((theme: Theme) => ({
	remove: {
		padding: theme.spacing(0.25, 3),
		marginRight: theme.spacing(1),
	},
	name: {
		flex: 1,
	},
	form: {
		width: "100%",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	heading: {
		[theme.breakpoints.up(theme.customBreakpoints.md)]: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
		},
		height: theme.spacing(3.75),
		width: "100%",
		marginBottom: theme.spacing(1),
	},
	doctorInfo: {
		flex: 1,
		// TODO: There's got to be a better way to style this
		minWidth: "calc(100% - 230px)",
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			display: "none",
		},
		[theme.breakpoints.up("md")]: {
			maxWidth: "calc(100% - 230px)",
		},
	},
	image_upload: {
		flex: 1,
		marginRight: theme.spacing(1),
		marginBottom: theme.spacing(2),
	},
	editor: {
		marginBottom: theme.spacing(4),
	},
	editor_title: {
		fontWeight: theme.typography.fontWeightBold,
	},
	divider: {
		marginBottom: theme.spacing(8),
		marginTop: theme.spacing(4),
	},
	buttonsFooter: {
		marginBottom: theme.spacing(2),
		textAlign: "right",
	},
}));

interface TeamMemberProps {
	onSave: React.Dispatch<React.SetStateAction<boolean>>;
	onDelete: (id: string) => void;
	member: TeamMemberType;
	isNew: boolean;
	portalId: string;
	setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TeamMember: React.FC<TeamMemberProps> = ({
	onSave,
	onDelete,
	member,
	isNew,
	portalId,
	setVisible,
}) => {
	const classes = useStyles();
	const [file, setFile] = React.useState<File | null>(null);
	const [bio, setBio] = React.useState<EditorState>(
		deserialize(member.biography)
	);
	const [open, setOpen] = React.useState<boolean>(false);
	const { setSnackbarProps } = useGlobalSnackbar();

	const [
		addTeamMemberMutation,
		addTeamMemberMutationResponse,
	] = useAddTeamMemberMutation({
		variables: {
			portalId,
			teamMember: member,
		},
	});

	const [
		editTeamMemberMutation,
		editTeamMemberMutationResponse,
	] = useEditTeamMemberMutation({
		variables: {
			portalId,
			id: member.teamMemberId,
			teamMember: member,
		},
	});

	const [uploadImageMutation, uploadImageResponse] = useUploadImageMutation({
		variables: {
			file: new File([], ""),
			publicId: "",
			tags: [],
		},
	});

	React.useEffect(() => {
		onSave(
			addTeamMemberMutationResponse.loading ||
				editTeamMemberMutationResponse.loading ||
				uploadImageResponse.loading
		);
	}, [
		addTeamMemberMutationResponse.loading,
		editTeamMemberMutationResponse.loading,
		onSave,
		uploadImageResponse.loading,
	]);

	const initialValues: TeamMemberInput = React.useMemo(() => {
		return {
			firstName: member.firstName || "",
			middleName: member.middleName || "",
			lastName: member.lastName || "",
			designation: member.designation || "",
			biography: member.biography || "",
		};
	}, [member]);

	const handleImageChange = (newImageFile: File) => {
		setFile(newImageFile);
	};

	const publicId = cloudinaryUtil.teamMember(
		parseInt(portalId),
		member.teamMemberId
	);

	// const publicId = `${cloudinaryConfig.dentalTeamPhotosFolder(
	//   parseInt(portalId)
	// )}/${member.teamMemberId}`;

	return (
		<div>
			<Formik<TeamMemberInput>
				initialValues={initialValues}
				onSubmit={async (teamMember) => {
					if (member.teamMemberId === "") {
						const result = await addTeamMemberMutation({
							variables: {
								portalId,
								teamMember: {
									firstName: teamMember.firstName,
									middleName: teamMember.middleName,
									lastName: teamMember.lastName,
									designation: teamMember.designation,
									biography: serialize(bio),
								},
							},
							refetchQueries: [
								{
									query: TeamMembersDocument,
									variables: {
										portalId: portalId,
									},
								},
							],
							awaitRefetchQueries: true,
						});
						if (result?.data) {
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: true,
								message: "New Team Member Saved!",
							});

							const newId =
								result.data.addTeamMember[result.data.addTeamMember.length - 1]
									.teamMemberId;

							const newPublicId = cloudinaryUtil.teamMember(portalId, newId);

							// const newPublicId = `${cloudinaryConfig.dentalTeamPhotosFolder(
							//   parseInt(portalId)
							// )}/${newId}`;

							if (file) {
								const result = await uploadImageMutation({
									variables: {
										file,
										publicId: newPublicId.publicId,
										tags: [],
									},
									refetchQueries: [
										{
											query: TeamMembersDocument,
											variables: {
												portalId: portalId,
											},
										},
									],
									awaitRefetchQueries: true,
								});
								if (result?.data) {
									setFile(null);
									setVisible && setVisible(false);
									setSnackbarProps({
										autoHideDuration: 5000,
										open: true,
										success: true,
										message: "New Team Member Image Saved!",
									});
								} else {
									const error =
										result?.errors?.[0] && mapGraphQlError(result.errors[0]);
									setSnackbarProps({
										autoHideDuration: 5000,
										open: true,
										success: false,
										message:
											error?.displayableError ||
											"New Team Member Image Not Saved!",
									});
								}
							} else {
								setVisible && setVisible(false);
							}
						} else {
							const error =
								result?.errors?.[0] && mapGraphQlError(result.errors[0]);
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: false,
								message:
									error?.displayableError || "New Team Member Not Saved!",
							});
						}
					} else {
						if (file) {
							const result = await uploadImageMutation({
								variables: {
									file,
									publicId: publicId.publicId,
									tags: [],
								},
							});
							if (result?.data) {
								setFile(null);
								setSnackbarProps({
									autoHideDuration: 5000,
									open: true,
									success: true,
									message: "Team Member Image Saved!",
								});
							} else {
								const error =
									result?.errors?.[0] && mapGraphQlError(result.errors[0]);
								setSnackbarProps({
									autoHideDuration: 5000,
									open: true,
									success: false,
									message:
										error?.displayableError || "Team Member Image Not Saved!",
								});
							}
						}

						const result = await editTeamMemberMutation({
							variables: {
								portalId,
								id: member.teamMemberId,
								teamMember: {
									firstName: teamMember.firstName,
									middleName: teamMember.middleName,
									lastName: teamMember.lastName,
									designation: teamMember.designation,
									biography: serialize(bio),
								},
							},
							refetchQueries: [
								{
									query: TeamMembersDocument,
									variables: {
										portalId: portalId,
									},
								},
							],
							awaitRefetchQueries: true,
						});
						if (result?.data) {
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: true,
								message: "Team Member Saved!",
							});
						} else {
							const error =
								result?.errors?.[0] && mapGraphQlError(result.errors[0]);
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: false,
								message: error?.displayableError || "Team Member Not Saved!",
							});
						}
					}
				}}
				enableReinitialize={true}
			>
				{(props) => (
					<Form
						id={`team_member_form_${member.teamMemberId}`}
						className={classes.form}
					>
						<div className={classes.image_upload}>
							<ImageUpload
								id={member.teamMemberId}
								publicId={member.headShot ? publicId.publicId : undefined}
								width={200}
								aspectRatio={AspectRatios.PortraitStandard}
								image={member.headShot}
								onChange={handleImageChange}
								defaultImage={headshotPlaceholder}
							/>
						</div>
						<div className={classes.doctorInfo}>
							<div className={classes.heading}>
								<Typography variant="h6" className={classes.name}>
									{formatTeamMemberName(props.values, true)}
								</Typography>
							</div>
							<div>
								<Field
									name="firstName"
									component={TextFieldAdapter}
									label="First Name"
								/>
							</div>
							<div>
								<Field
									name="middleName"
									component={TextFieldAdapter}
									label="Middle Name"
								/>
							</div>
							<div>
								<Field
									name="lastName"
									component={TextFieldAdapter}
									label="Last Name"
								/>
							</div>
							<div>
								<Field
									name="designation"
									component={TextFieldAdapter}
									label="Designation"
								/>
							</div>
							<div className={classes.editor}>
								<Typography
									variant="subtitle1"
									className={classes.editor_title}
								>
									Biography
								</Typography>
								<RichTextEditor value={bio} onChange={setBio} />
							</div>
							<div className={classes.buttonsFooter}>
								{!isNew && (
									<Button
										className={classes.remove}
										disabled={
											editTeamMemberMutationResponse.loading ||
											uploadImageResponse.loading
										}
										onClick={() => setOpen(true)}
										color="secondary"
									>
										remove
									</Button>
								)}

								{/* <Button
                  className={classes.save}
                  disabled={
                    editTeamMemberMutationResponse.loading ||
                    uploadImageResponse.loading
                  }
                  type="submit"
                  form={`team_member_form_${member.teamMemberId}`}
                  color="primary"
                  variant="contained"
                >
                  save
                </Button> */}
								<SaveButton
									disabled={
										editTeamMemberMutationResponse.loading ||
										uploadImageResponse.loading
									}
									form={`team_member_form_${member.teamMemberId}`}
								/>
							</div>
						</div>
					</Form>
				)}
			</Formik>

			<Divider className={classes.divider} />

			<RemoveTeamMemberDialog
				open={open}
				setOpen={setOpen}
				portalId={portalId}
				id={member.teamMemberId}
				onDeleteTeamMember={onDelete}
			/>
		</div>
	);
};
