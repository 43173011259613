import React from "react";

import { makeStyles, Theme, Button } from "@material-ui/core";

import { Layout } from "../../components/Layout";
import { RouteComponentProps, NavLink } from "react-router-dom";

import {
	useDoctorsQuery,
	Doctor as DoctorType,
} from "../../types/graphql-types";
import { Doctor } from "./Doctor";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { Tooltip, TooltipTypes } from "../../components/Tooltip";
import routes, { ProtectedDoctorRouteParams } from "../Routes";
import { formatDoctorName } from "../../utils/formatName";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import ContentHeader from "../../components/ContentHeader";

const useStyles = makeStyles((theme: Theme) => ({
	tooltips: {
		marginBottom: 34,
	},
	tooltip: {
		marginBottom: theme.spacing(2),
	},
	seeall: {
		textDecoration: "none",
	},
}));

export const DoctorProfile: React.FC<
	RouteComponentProps<ProtectedDoctorRouteParams>
> = ({ match }) => {
	const classes = useStyles();
	const portalId = match?.params?.portalId?.toString() || "";
	const npi = match?.params?.npi?.toString() || "";
	const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
	const { setSnackbarProps } = useGlobalSnackbar();
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { data, loading, error } = useDoctorsQuery({
		variables: {
			portalId,
			includeHeadShot: true,
			includeBio: true,
		},
	});

	const doctors = React.useMemo(() => {
		if (data) {
			return data.doctors;
		} else {
			return null;
		}
	}, [data]);

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Doctors!",
			});
		}
	}, [error, setSnackbarProps]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || saveLoading,
		});
	}, [loading, saveLoading, setLoadingIndicatorProps]);

	const headshotsMissing = React.useMemo(() => {
		const doctorNames = doctors
			?.filter((d) => !d.headShot)
			.map((d) => `${formatDoctorName(d)}: ${d.npi}`);
		return doctorNames?.join(", ") || "";
	}, [doctors]);

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title="Assigned Doctors"
				button={
					npi !== "" ? (
						<NavLink
							to={routes.DOCTOR_PROFILE.toPath({
								portalId,
							})}
							className={classes.seeall}
						>
							<Button color={"primary"} variant={"contained"}>
								see all doctors
							</Button>
						</NavLink>
					) : undefined
				}
				tooltipText="Upload doctor photos here. Biographies are set by the digital marketing team."
			/>

			<div className={classes.tooltips}>
				{headshotsMissing !== "" && (
					<div className={classes.tooltip}>
						<Tooltip
							type={TooltipTypes.Warning}
							text={
								<React.Fragment>
									Warning.{" "}
									{headshotsMissing.indexOf(":") ===
									headshotsMissing.lastIndexOf(":")
										? "Headshot"
										: "Headshots"}{" "}
									missing for {headshotsMissing}
								</React.Fragment>
							}
						/>
					</div>
				)}
			</div>

			<div>
				{npi === "" ? (
					doctors?.map((doctor: DoctorType) => (
						<Doctor key={doctor.npi} doctor={doctor} onSave={setSaveLoading} />
					))
				) : (
					<Doctor
						key={doctors?.find((doc) => doc.npi === npi)?.npi}
						doctor={doctors?.find((doc) => doc.npi === npi) || { npi: "" }}
						onSave={setSaveLoading}
					/>
				)}
			</div>
		</Layout>
	);
};
