import React from "react";
import { RouteComponentProps } from "react-router-dom";

import { Layout } from "../../components/Layout";
import routes, { ProtectedRouteParams } from "../Routes";
import {
	usePracticeMetadataQuery,
	useEditOfficeMetadataMutation,
	OfficeMetadataInput,
	PracticeMetadataDocument,
} from "../../types/graphql-types";
import { StateList } from "./StateList";
import { TextFieldAdapter } from "../../components/TextField";

import { makeStyles, Theme, Typography } from "@material-ui/core";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import * as Validator from "../../utils/validators";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { mapGraphQlError } from "../../utils/errorHandler";
// import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
// import ContentFooter from "../../components/ContentFooter";
import { Tooltip, TooltipTypes } from "../../components/Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
	form: {
		width: "100%",
		maxWidth: theme.centralBlockMaxWidth,
	},
	sectionTitle: {
		marginBottom: 40,
	},
	tooltip: {
		marginBottom: theme.spacing(4),
	},
	container: {
		display: "flex",
		flex: 1,
		justifyContent: "space-between",
		marginTop: "0 !important",
	},
	label: {
		top: -24,
		fontSize: 15,
	},
	select_form_control: {
		position: "relative",
		backgroundColor: theme.color.grey.light,
		marginTop: "0 !important",
		boxSizing: "border-box",
		height: 40,
		"& div > div > div": {
			paddingLeft: "0.75rem",
		},

		"& input": {
			height: 40,
			padding: `0 0 0 40px`,
		},

		"& label + .MuiInput-formControl": {
			marginTop: 8,
		},
	},
	input: {
		paddingTop: 8,
	},
	column: {
		flex: 1,
		justifyContent: "space-between",
		marginTop: "0 !important",
		marginBottom: 60,
		paddingLeft: 12,
		paddingRight: 12,

		"&:first-child": {
			paddingLeft: "0 !important",
		},

		"&:last-child": {
			paddingRight: "0 !important",
		},
	},
	error: {
		color: theme.color.red.main,
		padding: "0.5rem 0rem",
	},
	// this is to disable FMS editing per LS #567
	fieldset: {
		border: "none",
		pointerEvents: "none",
		opacity: 0.5,
	},
}));

const OfficeDetailsSchema = Yup.object().shape({
	hdcid: Yup.number().typeError("Value must be a number."),
	latitude: Yup.number().typeError("Value must be a number."),
	longitude: Yup.number().typeError("Value must be a number."),
	speeddial: Yup.number().typeError("Value must be a number."),
	phone: Yup.string()
		.min(10, "Phone numbers must be at least 10 characters")
		.max(18, "Phone numbers must be no more than 18 characters")
		.matches(
			/^(\+?\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
			"Must match a standard phone format."
		)
		.test("is-valid-number", "Please input a valid phone number", (value) =>
			Validator.isValidPhone(value)
		),
});

export const OfficeDetails: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ children, match }) => {
	const classes = useStyles();
	const portalId = match?.params?.portalId?.toString() || "";
	const [submitted, setSubmitted] = React.useState(false);
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = usePracticeMetadataQuery({
		variables: {
			portalId,
		},
	});

	const [
		editOfficeMetadataMutation,
		editOfficeMutationResponse,
	] = useEditOfficeMetadataMutation();

	const officeMetadataResult = data?.officeMetadata;

	// Strip out __typename fields from the response for initial values
	// Memoize the initial value to optimize re-renders
	const initialValues: OfficeMetadataInput = React.useMemo(() => {
		return {
			officeName: officeMetadataResult?.officeName || "",
			epicorId: officeMetadataResult?.epicorId || "",
			carecreditId: officeMetadataResult?.carecreditId || "",
			street1: officeMetadataResult?.street1 || "",
			postalCode: officeMetadataResult?.postalCode || "",
			city: officeMetadataResult?.city || "",
			provinceAbbr: officeMetadataResult?.provinceAbbr || "",
			hdcid: officeMetadataResult?.hdcid || (("" as unknown) as number),
			latitude: officeMetadataResult?.latitude || (("" as unknown) as number),
			longitude: officeMetadataResult?.longitude || (("" as unknown) as number),
			phone: officeMetadataResult?.phone || "",
			speeddial: officeMetadataResult?.speeddial || (("" as unknown) as number),
		};
	}, [officeMetadataResult]);

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Office Details!",
			});
		}
	}, [error, setSnackbarProps]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || editOfficeMutationResponse.loading,
		});
	}, [loading, editOfficeMutationResponse.loading, setLoadingIndicatorProps]);

	return (
		<Formik<OfficeMetadataInput>
			initialValues={initialValues}
			validationSchema={OfficeDetailsSchema}
			onSubmit={async (metadata: OfficeMetadataInput) => {
				const castedMetadata = { ...metadata };
				castedMetadata.hdcid = isNaN(parseInt(metadata.hdcid?.toString() || ""))
					? undefined
					: parseInt(metadata?.hdcid?.toString() || "");
				castedMetadata.latitude = isNaN(
					parseFloat(metadata.latitude?.toString() || "")
				)
					? undefined
					: parseFloat(metadata?.latitude?.toString() || "");
				castedMetadata.longitude = isNaN(
					parseFloat(metadata.longitude?.toString() || "")
				)
					? undefined
					: parseFloat(metadata?.longitude?.toString() || "");
				castedMetadata.speeddial = isNaN(
					parseInt(metadata.speeddial?.toString() || "")
				)
					? undefined
					: parseInt(metadata?.speeddial?.toString() || "");
				castedMetadata.province = StateList.find(
					(obj) => obj.value === castedMetadata.provinceAbbr
				)?.label;

				const result = await editOfficeMetadataMutation({
					variables: {
						portalId,
						officeMetadata: castedMetadata,
					},
					refetchQueries: [
						{
							query: PracticeMetadataDocument,
							variables: {
								portalId: portalId,
							},
						},
					],
					awaitRefetchQueries: true,
				});
				if (result?.data) {
					setSubmitted(true);
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: true,
						message: "Office Details Saved!",
					});
				} else {
					const error =
						result?.errors?.[0] && mapGraphQlError(result.errors[0]);
					setSnackbarProps({
						autoHideDuration: 5000,
						open: true,
						success: false,
						message: error?.displayableError || "Office Details Not Saved!",
					});
				}
			}}
			enableReinitialize={true}
		>
			{({ isValid }) => {
				return (
					<Layout portalId={match.params.portalId}>
						{submitted && (
							<div className={classes.tooltip}>
								<Tooltip
									type={TooltipTypes.Info}
									text="Office data has been updated. Please allow 24 hours for your changes to be reflected everywhere."
								/>
							</div>
						)}
						<ContentHeader
							title={routes.OFFICE_DETAILS.title}
							// button={
							// 	<SaveButton form="office-details-form" disabled={!isValid} />
							// }
							tooltipText="Office details are synced from GEMS and cannot be edited here."
						/>
						<Form id="office-details-form">
							<fieldset disabled className={classes.fieldset}>
								<Field
									name="officeName"
									component={TextFieldAdapter}
									label="Practice Name"
								/>
								<Field
									name="epicorId"
									component={TextFieldAdapter}
									label="Epicor ID"
								/>
								<Field
									name="carecreditId"
									component={TextFieldAdapter}
									label="Care Credit Id"
								/>
								<Typography variant="h6" className={classes.sectionTitle}>
									Location
								</Typography>
								<Field
									name="street1"
									component={TextFieldAdapter}
									label="Address"
								/>
								<Field
									name="postalCode"
									component={TextFieldAdapter}
									label="Zip Code"
								/>
								<div className={classes.container}>
									<div>
										<Field
											name="city"
											component={TextFieldAdapter}
											label="City"
										/>
									</div>
									<div className={classes.column}>
										<Field
											name="provinceAbbr"
											select
											label="State"
											component={TextFieldAdapter}
											options={StateList}
										></Field>
									</div>
								</div>
								<Typography variant="h6" className={classes.sectionTitle}>
									Google Maps
								</Typography>
								<div className={classes.container}>
									<div className={classes.column}>
										<Field
											name="hdcid"
											component={TextFieldAdapter}
											label="Location ID"
										/>
									</div>
									<div className={classes.column}>
										<Field
											name="latitude"
											component={TextFieldAdapter}
											label="Latitude"
										/>
									</div>
									<div className={classes.column}>
										<Field
											name="longitude"
											component={TextFieldAdapter}
											label="Longitude"
										/>
									</div>
								</div>
								<Typography variant="h6" className={classes.sectionTitle}>
									Telephone
								</Typography>
								<div className={classes.container}>
									<div className={classes.column}>
										<Field
											name="phone"
											component={TextFieldAdapter}
											label="Phone Number"
											InputProps={{
												inputProps: {
													maxLength: "18",
													minLength: "10",
												},
											}}
										/>
									</div>
									<div className={classes.column}>
										<Field
											name="speeddial"
											component={TextFieldAdapter}
											label="Speed Dial"
										/>
									</div>
								</div>
							</fieldset>
						</Form>
						{/* <ContentFooter>
							<SaveButton form="office-details-form" disabled={!isValid} />
						</ContentFooter> */}
					</Layout>
				);
			}}
		</Formik>
	);
};
