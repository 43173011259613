import { makeStyles, Theme, Grid } from "@material-ui/core";
import React from "react";
import { Tooltip, TooltipTypes } from "../Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
	content_header: {
		marginBottom: theme.spacing(4),
	},
	content_title: {
		fontSize: theme.typography.fontSizeContentTitle,
		fontWeight: theme.typography.fontWeightBold,
	},
	subtext: {
		marginBottom: theme.spacing(3),
	},
}));

interface ContentHeaderProps {
	button?: JSX.Element;
	title: string;
	tooltipText?: string;
	subtext?: string;
}
const ContentHeader: React.FC<ContentHeaderProps> = ({
	button,
	title,
	tooltipText,
	subtext,
}) => {
	const classes = useStyles();
	return (
		<Grid
			item
			container
			justify="space-between"
			spacing={subtext ? 1 : 3}
			className={classes.content_header}
		>
			<Grid item className={classes.content_title}>
				{title}
			</Grid>
			<Grid item>{button}</Grid>

			{subtext && (
				<Grid item xs={12} className={classes.subtext}>
					<em>{subtext}</em>
				</Grid>
			)}

			{tooltipText && (
				<Grid item xs={12}>
					<Tooltip
						type={TooltipTypes.Info}
						text={<React.Fragment>{tooltipText}</React.Fragment>}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default ContentHeader;
