import React from "react";
import { startCase } from "lodash";
import { Layout } from "../../components/Layout";
import { RouteComponentProps, NavLink } from "react-router-dom";
import {
	makeStyles,
	Theme,
	Typography,
	Card,
	Button,
	Chip,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ErrorOutlinedIcon from "@material-ui/icons/ErrorOutline";
import WarningIcon from "@material-ui/icons/ReportProblemOutlined";
import {
	usePracticeMetadataQuery,
	useDoctorsQuery,
	useTeamMembersQuery,
	useOfficeHoursMetadataQuery,
	TeamMemberFragment,
	Doctor,
} from "../../types/graphql-types";
import cloudinaryUtil from "../../utils/cloudinaryUtil";
import routes, { ProtectedRouteParams } from "../Routes";
import { Tooltip, TooltipTypes } from "../../components/Tooltip";
import defaultImage from "../../assets/images/placeholder-image.png";
import { formatDoctorName, formatTeamMemberName } from "../../utils/formatName";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { RichTextViewer } from "../../components/RichTextViewer";

const useStyles = makeStyles((theme: Theme) => ({
	content: {
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
	},
	main: {
		flex: 1,
	},
	officeName: {
		marginBottom: theme.spacing(4),
	},
	tooltip: {
		marginBottom: theme.spacing(4),
	},
	section_header: {
		display: "flex",
	},
	edit_button: {
		marginLeft: theme.spacing(2),
	},
	cards: {
		display: "flex",
		flexWrap: "wrap",
	},
	cardNavLink: {
		textDecoration: "none",
	},
	navLink: {
		textDecoration: "none",
	},
	clickableCard: {
		cursor: "pointer",
		padding: theme.spacing(2),
		margin: theme.spacing(2),

		"&:hover": {
			backgroundColor: theme.color.grey.light,
		},
	},
	imgContainer: {
		alignItems: "center",
		backgroundColor: theme.color.grey.light,
		display: "flex",
		// 3:4 aspect ratio is standard for portraits
		height: 100,
		width: 75,
		justifyContent: "center",
		margin: "0px auto 8px",
		backgroundSize: "cover",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
	},
	name: {
		textAlign: "center",
	},
	viewMore: {
		display: "flex",
		alignItems: "center",
		height: 133,
	},
	detailsHeader: {
		display: "flex",
	},
	officeDetails: {
		flex: 1,
		marginBottom: theme.spacing(2),
	},
	officeDetailsSubheading: {
		marginBottom: theme.spacing(2),
	},
	section: {
		backgroundColor: theme.color.grey.light,
		marginBottom: theme.spacing(2),
		padding: theme.spacing(2),
	},
	bannerText: {
		margin: theme.spacing(2),
		padding: theme.spacing(2),
		"& a": {
			pointerEvents: "none",
			color: theme.palette.text.disabled,
		},
	},
	bannerChipInfo: {
		background: theme.color.blue.light,
		color: theme.color.blue.dark,
		fontWeight: theme.typography.fontWeightBold,
		marginLeft: theme.spacing(1),
		"& svg": {
			color: theme.color.blue.dark,
		},
	},
	bannerChipWarning: {
		background: theme.color.yellow.light,
		color: theme.color.yellow.dark,
		fontWeight: theme.typography.fontWeightBold,
		marginLeft: theme.spacing(1),
		"& svg": {
			color: theme.color.yellow.dark,
		},
	},
	bannerChipError: {
		background: theme.color.red.light,
		color: theme.color.red.dark,
		fontWeight: theme.typography.fontWeightBold,
		marginLeft: theme.spacing(1),
		"& svg": {
			color: theme.color.red.dark,
		},
	},
	bannerDisclaimer: {
		marginBottom: theme.spacing(4),
	},
}));

export const Dashboard: React.FC<RouteComponentProps<ProtectedRouteParams>> = ({
	match,
}) => {
	const classes = useStyles();
	const portalId = match.params.portalId || "";
	const { setSnackbarProps } = useGlobalSnackbar();
	const {
		loadingIndicatorProps,
		setLoadingIndicatorProps,
	} = useGlobalLoadingIndicator();

	const practiceMetadataResponse = usePracticeMetadataQuery({
		variables: {
			portalId,
		},
	});

	const doctorsResponse = useDoctorsQuery({
		variables: {
			portalId: portalId,
			includeHeadShot: true,
			includeBio: false,
		},
	});

	const teamMemberResponse = useTeamMembersQuery({
		variables: {
			portalId,
		},
	});

	const officeHoursMetadataResponse = useOfficeHoursMetadataQuery({
		variables: {
			portalId,
		},
	});

	React.useEffect(() => {
		if (
			practiceMetadataResponse.error ||
			doctorsResponse.error ||
			teamMemberResponse.error ||
			officeHoursMetadataResponse.error
		) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch all Dashboard Data!",
			});
		}
		setLoadingIndicatorProps({
			loading:
				practiceMetadataResponse.loading ||
				doctorsResponse.loading ||
				teamMemberResponse.loading ||
				officeHoursMetadataResponse.loading,
		});
	}, [
		setLoadingIndicatorProps,
		setSnackbarProps,
		practiceMetadataResponse,
		doctorsResponse,
		teamMemberResponse,
		officeHoursMetadataResponse,
	]);

	const buildDoctorPublicId = (doctor: Doctor) => {
		return doctor.headShot
			? cloudinaryUtil.imageSrc({
					version: doctor.headShot.version,
					publicId: cloudinaryUtil.doctor(doctor.npi).publicId,
			  })
			: defaultImage;
	};

	const buildTeamMemberPublicId = (member: TeamMemberFragment) => {
		return member.headShot
			? cloudinaryUtil.imageSrc({
					version: member.headShot.version,
					publicId: cloudinaryUtil.teamMember(portalId, member.teamMemberId)
						.publicId,
			  })
			: defaultImage;
	};

	const getBannerTypeChip = () => {
		if (practiceMetadataResponse.data) {
			const { officeMetadata } = practiceMetadataResponse.data;
			let Icon: React.ComponentType;
			let text: string;
			let className: string;
			switch (officeMetadata.alertType) {
				case TooltipTypes.Info:
					Icon = InfoOutlinedIcon;
					text = "Information";
					className = classes.bannerChipInfo;
					break;
				case TooltipTypes.Warning:
					Icon = WarningIcon;
					text = "Warning";
					className = classes.bannerChipWarning;
					break;
				case TooltipTypes.Critical:
					Icon = ErrorOutlinedIcon;
					text = "Critical";
					className = classes.bannerChipError;
					break;
				default:
					return undefined;
			}

			return (
				<Chip
					icon={<Icon />}
					label={text}
					classes={{
						root: className,
					}}
				/>
			);
		}
		return undefined;
	};

	return (
		<Layout portalId={portalId}>
			{!loadingIndicatorProps.loading && (
				<React.Fragment>
					<div className={classes.tooltip}>
						<Tooltip
							type={TooltipTypes.Info}
							text={
								<React.Fragment>
									This is your site's dashboard. It offers a quick glance at
									some office details. Use the navigation to the left to edit
									these and more.
								</React.Fragment>
							}
						/>
					</div>
					<Typography variant="h5" className={classes.officeName}>
						{practiceMetadataResponse.data?.officeMetadata.officeName}
					</Typography>
					<div className={classes.content}>
						<div className={classes.main}>
							<div className={classes.section_header}>
								<Typography variant="h6">Doctors</Typography>
								<NavLink
									to={routes.DOCTOR_PROFILE.toPath({ portalId })}
									className={classes.navLink}
								>
									<Button color="primary" className={classes.edit_button}>
										Edit
									</Button>
								</NavLink>
							</div>
							<div className={classes.cards}>
								{(doctorsResponse.data?.doctors.length || 0) > 3 ? (
									<React.Fragment>
										{doctorsResponse.data?.doctors
											.slice(0, 3)
											.map((doctor: Doctor) => (
												<NavLink
													key={doctor.npi}
													to={routes.DOCTOR.toPath({
														portalId,
														npi: doctor.npi,
													})}
													className={classes.cardNavLink}
												>
													<Card className={classes.clickableCard}>
														<div
															className={classes.imgContainer}
															style={{
																backgroundImage: `url(${buildDoctorPublicId(
																	doctor
																)})`,
															}}
															role="img"
															aria-label={`Doctor ${doctor.firstName} ${doctor.lastName} headshot`}
														/>
														<div className={classes.name}>
															{formatDoctorName(doctor, false)}
														</div>
													</Card>
												</NavLink>
											))}
										<NavLink
											to={routes.DOCTOR_PROFILE.toPath({
												portalId,
											})}
											className={classes.cardNavLink}
										>
											<Card className={classes.clickableCard}>
												<div className={classes.viewMore}>
													+{(doctorsResponse.data?.doctors.length || 0) - 3}{" "}
													More{" "}
													{(doctorsResponse.data?.doctors.length || 0) - 3 === 1
														? "Doctor"
														: "Doctors"}
												</div>
											</Card>
										</NavLink>
									</React.Fragment>
								) : (
									doctorsResponse.data?.doctors.map((doctor: Doctor) => (
										<NavLink
											key={doctor.npi}
											to={routes.DOCTOR.toPath({
												portalId,
												npi: doctor.npi,
											})}
											className={classes.cardNavLink}
										>
											<Card className={classes.clickableCard}>
												<div
													className={classes.imgContainer}
													style={{
														backgroundImage: `url(${buildDoctorPublicId(
															doctor
														)})`,
													}}
													role="img"
													aria-label={`Doctor ${doctor.firstName} ${doctor.lastName} headshot`}
												/>
												<div className={classes.name}>
													{formatDoctorName(doctor, false)}
												</div>
											</Card>
										</NavLink>
									))
								)}
							</div>

							<div className={classes.section_header}>
								<Typography variant="h6">Team</Typography>
								<NavLink
									to={routes.ABOUT_YOUR_TEAM.toPath({ portalId })}
									className={classes.navLink}
								>
									<Button color="primary" className={classes.edit_button}>
										Edit
									</Button>
								</NavLink>
							</div>
							<div className={classes.cards}>
								{(teamMemberResponse.data?.teamMembers.length || 0) > 3 ? (
									<React.Fragment>
										{teamMemberResponse.data?.teamMembers
											.slice(0, 3)
											.map((member: TeamMemberFragment) => (
												<NavLink
													key={member.teamMemberId}
													to={routes.TEAM_MEMBER.toPath({
														portalId,
														teamMemberId: member.teamMemberId,
													})}
													className={classes.cardNavLink}
												>
													<Card className={classes.clickableCard}>
														<div
															className={classes.imgContainer}
															style={{
																backgroundImage: `url(${buildTeamMemberPublicId(
																	member
																)})`,
															}}
															role="img"
															aria-label={`Team member ${member.firstName} ${member.lastName} headshot`}
														/>
														<div className={classes.name}>
															{formatTeamMemberName(member)}
														</div>
													</Card>
												</NavLink>
											))}
										<NavLink
											to={routes.ABOUT_YOUR_TEAM.toPath({
												portalId,
											})}
											className={classes.cardNavLink}
										>
											<Card className={classes.clickableCard}>
												<div className={classes.viewMore}>
													+
													{(teamMemberResponse.data?.teamMembers.length || 0) -
														3}{" "}
													More{" "}
													{(teamMemberResponse.data?.teamMembers.length || 0) -
														3 ===
													1
														? "Member"
														: "Members"}
												</div>
											</Card>
										</NavLink>
									</React.Fragment>
								) : (
									teamMemberResponse.data?.teamMembers.map(
										(member: TeamMemberFragment) => (
											<NavLink
												key={member.teamMemberId}
												to={routes.TEAM_MEMBER.toPath({
													portalId,
													teamMemberId: member.teamMemberId,
												})}
												className={classes.cardNavLink}
											>
												<Card className={classes.clickableCard}>
													<div
														className={classes.imgContainer}
														style={{
															backgroundImage: `url(${buildTeamMemberPublicId(
																member
															)})`,
														}}
														role="img"
														aria-label={`Team member ${member.firstName} ${member.lastName} headshot`}
													/>
													<div className={classes.name}>
														{formatTeamMemberName(member)}
													</div>
												</Card>
											</NavLink>
										)
									)
								)}
							</div>

							{practiceMetadataResponse.data?.officeMetadata.alertMessage && (
								<React.Fragment>
									<div className={classes.section_header}>
										<Typography variant="h6">
											Website Banner is Enabled
										</Typography>
									</div>
									<div className={classes.cards}>
										<div>
											<b>Type:</b>
											{getBannerTypeChip()}
										</div>

										<Card className={classes.bannerText}>
											<RichTextViewer
												value={
													practiceMetadataResponse.data.officeMetadata
														.alertMessage
												}
											/>
										</Card>

										<div className={classes.bannerDisclaimer}>
											Banners are centrally managed. Please contact event
											manager to change/modify the banner.
										</div>
									</div>
								</React.Fragment>
							)}
						</div>
						<div>
							<div>
								<div className={classes.detailsHeader}>
									<Typography variant="h6" className={classes.officeDetails}>
										Office Details
									</Typography>
									<NavLink
										to={routes.OFFICE_DETAILS.toPath({ portalId })}
										className={classes.navLink}
									>
										<Button color="primary" className={classes.edit_button}>
											Edit
										</Button>
									</NavLink>
								</div>
								<div className={classes.section}>
									<Typography
										variant="h6"
										className={classes.officeDetailsSubheading}
									>
										Address:
									</Typography>
									<div>
										{practiceMetadataResponse.data?.officeMetadata.street1}
									</div>
									<div>
										{practiceMetadataResponse.data?.officeMetadata.city},{" "}
										{practiceMetadataResponse.data?.officeMetadata.provinceAbbr}
										, {practiceMetadataResponse.data?.officeMetadata.postalCode}
									</div>
								</div>
								<div className={classes.section}>
									<Typography
										variant="h6"
										className={classes.officeDetailsSubheading}
									>
										Phone:
									</Typography>
									<div>
										{practiceMetadataResponse.data?.officeMetadata.phone}
									</div>
									{practiceMetadataResponse.data?.officeMetadata.speeddial ? (
										<div>
											Speed Dial:{" "}
											{practiceMetadataResponse.data.officeMetadata.speeddial}
										</div>
									) : null}
								</div>
								<div className={classes.section}>
									<Typography
										variant="h6"
										className={classes.officeDetailsSubheading}
									>
										Office Hours:
									</Typography>
									<div>
										{Object.keys(
											officeHoursMetadataResponse.data?.officeMetadata
												.officeHours || {}
										)
											.filter(
												(day: string) =>
													officeHoursMetadataResponse.data?.officeMetadata
														.officeHours?.[day] !== null && day !== "__typename"
											)
											.map((day: string) => (
												<div key={day}>
													{startCase(day)}:{" "}
													{
														officeHoursMetadataResponse.data?.officeMetadata
															.officeHours?.[day].open
													}
													{" - "}
													{
														officeHoursMetadataResponse.data?.officeMetadata
															.officeHours?.[day].close
													}
												</div>
											))}
									</div>
								</div>
							</div>
						</div>
						<div></div> {/*  This is Adam's empty div for cool stuff  */}
					</div>
				</React.Fragment>
			)}
		</Layout>
	);
};
