import React from "react";

import { makeStyles, Typography, Theme, Link } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
	title: {
		fontWeight: "bold",
	},
	row: {
		padding: 8,
		"&:nth-child(odd)": {
			backgroundColor: theme.color.grey.light,
		},
	},
	row_link: {
		cursor: "pointer",
		display: "block",
		width: "100%",
	},
}));

export const TrainingDocuments: React.FC<{}> = () => {
	const classes = useStyles();

	return (
		<React.Fragment>
			<Typography className={classes.title}>Training Documents</Typography>
			<div className={classes.row}>
				<Link
					className={classes.row_link}
					href="https://docs.lakestreet.heartland.com/reference/smile-gallery/"
					target="_smile-gallery"
				>
					Smile Gallery
				</Link>
			</div>
			<div className={classes.row}>
				<Link
					className={classes.row_link}
					href="https://docs.lakestreet.heartland.com/reference/doctor-assignment/"
					target="_doctor-assignment"
				>
					Doctor Assignment
				</Link>
			</div>
			<div className={classes.row}>
				<Link
					className={classes.row_link}
					href="https://docs.lakestreet.heartland.com/reference/colors-and-styles/"
					target="_colors-and-styles"
				>
					Colors and Styles
				</Link>
			</div>
			<div className={classes.row}>
				<Link
					className={classes.row_link}
					href="https://docs.lakestreet.heartland.com/reference/widget-manager/"
					target="_widget-manager"
				>
					Widget Manager
				</Link>
			</div>
			<div className={classes.row}>
				<Link
					className={classes.row_link}
					href="https://docs.lakestreet.heartland.com/reference/deploy-website/"
					target="_deploy-website"
				>
					How to Deploy Website Changes
				</Link>
			</div>
			<div className={classes.row}>
				<Link
					className={classes.row_link}
					href="https://heartlanddental.sharepoint.com/sites/DigitalMarketing/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FDigitalMarketing%2FShared%20Documents%2F2019%20Website%20Customizations%2Epdf&parent=%2Fsites%2FDigitalMarketing%2FShared%20Documents"
					target="_website-customizations"
				>
					Website Customizations
				</Link>
			</div>
		</React.Fragment>
	);
};
