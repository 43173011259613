import React from "react";

import { makeStyles, Typography, Theme } from "@material-ui/core";

import { Layout } from "../../components/Layout";
import { OfficeSearch } from "../../components/OfficeSearch";
import { Tooltip, TooltipTypes } from "../../components/Tooltip";
import { RecentOffices } from "./RecentOffices";
import { TrainingDocuments } from "./TrainingDocuments";

const useStyles = makeStyles((theme: Theme) => ({
	greeting: {
		color: theme.color.grey.main,
		marginTop: 30,
		textAlign: "center",
	},
	section_main: {
		margin: "auto",
		marginBottom: 30,
		marginTop: 30,
		maxWidth: 900,
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			marginBottom: 12,
			width: "100%",
		},
	},
	input_label: {
		color: theme.color.grey.main,
	},
	section: {
		display: "flex",
		margin: "auto",
		maxWidth: 900,
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			display: "block",
		},
	},
	recent_offices_section: {
		flex: 1,
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			flex: "unset",
			width: "100%",
		},
	},
	training_docs_section: {
		flex: 1,
		paddingLeft: 24,
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			flex: "unset",
			paddingLeft: 0,
			width: "100%",
		},
	},
	row: {
		padding: 8,
		"&:nth-child(odd)": {
			backgroundColor: theme.color.grey.light,
		},
	},
	row_link: {
		cursor: "pointer",
		display: "block",
		width: "100%",
	},
	tooltip: {
		height: 125,
		marginTop: 32,
	},
	quote: {
		color: theme.color.grey.main,
		fontWeight: "bold",
		margin: "auto",
		marginBottom: 60,
		marginTop: 60,
		maxWidth: 900,
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			marginBottom: 12,
			width: "100%",
		},
	},
	footerGraphic: {
		margin: "auto",
		marginBottom: theme.spacing(4),
		maxWidth: 900,
		[theme.breakpoints.down(theme.customBreakpoints.sm)]: {
			marginBottom: 12,
			width: "100%",
		},
	},
	graphic_main: {
		display: "flex",
	},
	graphicYellow: {
		backgroundColor: theme.color.yellow.main,
		flex: 1,
	},
	graphicTeal: {
		backgroundColor: theme.color.teal.main,
		flex: 1,
		height: theme.spacing(2),
	},
	graphicGreen: {
		backgroundColor: theme.color.green.light,
		flex: 1,
		height: theme.spacing(2),
	},
	graphicBlue: {
		backgroundColor: theme.palette.primary.main,
		flex: 1,
		height: theme.spacing(2),
	},
	graphicPurple: {
		backgroundColor: theme.color.purple.main,
		flex: 1,
		height: theme.spacing(2),
	},
	graphicPink: {
		backgroundColor: theme.color.pink.main,
		flex: 1,
		height: theme.spacing(2),
	},
	graphicOrange: {
		backgroundColor: theme.color.orange.main,
		flex: 1,
		height: theme.spacing(2),
	},
	graphicPaleOrange: {
		backgroundColor: theme.color.orange.light,
		flex: 1,
		height: theme.spacing(2),
	},
	graphicText: {
		color: theme.color.grey.main,
		fontWeight: "bold",
		flex: 2,
		textAlign: "center",
		marginTop: 4,

		"&:nth-child(2n + 1)": {
			flex: 1,
		},
	},
}));

const greetingTextVariations = [
	"Hello!",
	"Guten tag!",
	"Namaste.",
	"Oye Beltalowda!",
	"Tonk’peh.",
	"Howdy!",
	"Bonjour!",
	"здравствуйте!",
	"こんにちは",
];

const tooltipVariations = [
	"Did you know? You can customize which services are shown on any site you manage.",
	"Did you know? Updating office hours is easy!",
	"Did you know? You can add office staff team members to your site. ",
	"Your doctor can add their own personal touch to any service page with the Doctor's Chair feature.",
	"Did you know? The Biscayne and Congaree templates allow your doctor to add their  mission statement to the home page.",
	"Did you know? The widget manager allows you to easily drag and drop to customize your home page.",
	"Did you know? Effingham was first settled in 1814, and was known from then until 1859 as Broughton.",
	"Did you know? Doc Holiday earned his DDS degree from the Pennsylvania College of Dental Surgery and had to wait five months till his 21st birthday to legally practice dentistry.",
	"Did you know? It takes 17 muscles to smile and 43 to frown.",
	"Did you know? Egyptians used a form of toothpaste over 5,000 years ago.",
	"Did you know? The first nylon bristled toothbrush with a plastic handle was invented in 1938 by Dupont.",
	"Did you know? All of our websites are published overnight and updates are usually live within 24 hours.",
	"Did you know? Our websites are cached (stored in memory) on Amazon's web servers to deliver the fastest, most reliable, connection to the user.",
	"Did you know? In 2019 Heartland Dental office websites experienced zero seconds of downtime.",
];

const chooseRandomIndex = (currentIndex: number, max: number) => {
	const rand = Math.random() * max;
	let roundedRand = 0;

	if (rand > 4) {
		roundedRand = Math.ceil(rand);
	} else {
		roundedRand = Math.floor(rand);
	}

	if (roundedRand === currentIndex) {
		if (currentIndex !== 0) {
			roundedRand--;
		} else {
			roundedRand++;
		}
	}

	return roundedRand;
};

export const Home: React.FC<{}> = () => {
	const classes = useStyles();
	const [tooltipIndex, setTooltipIndex] = React.useState(0);
	const [greetingIndex] = React.useState(
		chooseRandomIndex(-1, greetingTextVariations.length - 1)
	);

	const handleSeeMoreTips = () => {
		setTooltipIndex(
			chooseRandomIndex(tooltipIndex, tooltipVariations.length - 1)
		);
	};

	return (
		<Layout portalId="">
			<Typography variant="h3" gutterBottom={true} className={classes.greeting}>
				{greetingTextVariations[greetingIndex]}
			</Typography>
			<div className={classes.section_main}>
				<Typography
					variant="subtitle1"
					gutterBottom={true}
					className={classes.input_label}
				>
					Find an Office
				</Typography>
				<OfficeSearch inNav={false} />
				<div className={classes.tooltip}>
					<Tooltip
						type={TooltipTypes.Info}
						text={
							<React.Fragment>{tooltipVariations[tooltipIndex]}</React.Fragment>
						}
						actionText="See More Tips"
						action={handleSeeMoreTips}
					/>
				</div>
			</div>
			<div className={classes.section}>
				<div className={classes.recent_offices_section}>
					<RecentOffices />
				</div>
				<div className={classes.training_docs_section}>
					<TrainingDocuments />
				</div>
			</div>
			<div className={classes.quote}>
				<em>
					"To support Doctors and their teams as they build community presence
					by providing marketing expertise and a robust set of tools, best
					practices, and strategies that attract and retain patients who repeat
					and refer."
				</em>
			</div>
			<div className={classes.footerGraphic}>
				<div className={classes.graphic_main}>
					<div className={classes.graphicYellow}></div>
					<div className={classes.graphicGreen}></div>
					<div className={classes.graphicTeal}></div>
					<div className={classes.graphicBlue}></div>
					<div className={classes.graphicPurple}></div>
					<div className={classes.graphicPink}></div>
					<div className={classes.graphicOrange}></div>
					<div className={classes.graphicPaleOrange}></div>
				</div>
				<div className={classes.graphic_main}>
					<div className={classes.graphicText}>Strategic</div>
					<div className={classes.graphicText}>Innovative</div>
					<div className={classes.graphicText}>Impactful</div>
				</div>
			</div>
		</Layout>
	);
};
