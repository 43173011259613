import React from "react";
import { NavLink } from "react-router-dom";

import { makeStyles, Typography, Theme, Link } from "@material-ui/core";

import { Route } from "../../../routes/Routes";
import {
	useBuildOfficeMutation,
	useDeployOfficeMutation,
} from "../../../types/graphql-types";
import { DeployChangesDialog } from "../../../routes/DeployChanges/DeployChangesDialog";
import { ProgressDialog } from "../../../components/ProgressDialog";
import { useGlobalSnackbar } from "../../GlobalSnackbar";

const useStyles = makeStyles((theme: Theme) => ({
	title: {
		color: theme.color.grey.dark,
		display: "inline-block",
		fontWeight: "bold",
		paddingLeft: 4,
		verticalAlign: "top",
	},
	link: {
		color: theme.color.grey.dark,
		fontSize: "0.75rem",
		fontWeight: "normal",
		display: "block",
		paddingLeft: "0.5rem",
		textDecoration: "none",

		"&:last-child": {
			marginBottom: theme.spacing(3),
		},
	},
	deployLink: {
		color: theme.color.grey.dark,
		cursor: "pointer",
		fontSize: "0.75rem",
		fontWeight: "normal",
		display: "block",
		paddingLeft: "0.5rem",
		textDecoration: "none",

		"&:hover": {
			textDecoration: "none",
		},
	},
	active_link: {
		backgroundColor: theme.palette.primary.main,
		borderRadius: "0.75rem",
		paddingLeft: "0.5rem",
		paddingRight: "0.5rem",
		width: "max-content",
		color: theme.color.white.main,
		fontWeight: "bold",
		fontSize: "0.75rem",
		display: "block",
		textDecoration: "none",

		"&:last-child": {
			marginBottom: theme.spacing(3),
		},
	},
}));

type SectionProps = {
	title: string;
	routes?: Array<Route>;
	deploy?: boolean;
	portalId: string;
};

export const Section: React.FC<SectionProps> = ({
	children,
	title,
	routes,
	deploy,
	portalId,
}) => {
	const classes = useStyles();
	const [modalOpen, setModalOpen] = React.useState<boolean>(false);
	const [progressOpen, setProgressOpen] = React.useState<boolean>(false);
	const { setSnackbarProps } = useGlobalSnackbar();
	const [buildOfficeMutation] = useBuildOfficeMutation();
	const [deployOfficeMutation] = useDeployOfficeMutation();

	const handlePublish = async () => {
		setModalOpen(false);
		setProgressOpen(true);

		const showSuccess = () => {
			setProgressOpen(false);
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: true,
				message: "Changes Deployed!",
			});
		};

		const showFailure = () => {
			setProgressOpen(false);
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Deployment Failed!",
			});
		};

		if (portalId) {
			const buildOfficeResult = await buildOfficeMutation({
				variables: { portalId },
			});
			if (buildOfficeResult?.errors) {
				showFailure();
				return;
			}
			const deployResult = await deployOfficeMutation({
				variables: { portalId },
			});
			if (deployResult?.errors) {
				showFailure();
				return;
			}
			showSuccess();
		}
	};

	return (
		<div>
			<div>
				{children}
				<Typography variant="subtitle1" className={classes.title}>
					{title}
					{routes?.map((route: Route) => (
						<NavLink
							key={route.path}
							to={route.toPath({ portalId })}
							className={classes.link}
							activeClassName={classes.active_link}
							onClick={() =>
								setSnackbarProps({ open: false, message: "", success: true })
							}
						>
							{route.title}
						</NavLink>
					))}
					{deploy && (
						<Link
							onClick={() => {
								setModalOpen(true);
							}}
							className={classes.deployLink}
						>
							Deploy Changes
						</Link>
					)}
				</Typography>
			</div>

			<DeployChangesDialog
				open={modalOpen}
				setOpen={setModalOpen}
				onPublish={handlePublish}
			/>

			<ProgressDialog
				open={progressOpen}
				message="This may take a while, do not refresh the page or close this window."
			/>
		</div>
	);
};
