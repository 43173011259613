import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react/context/ApolloProvider";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { GlobalSnackbarProvider } from "./components/GlobalSnackbar";
import Routes from "./routes";
import { createApolloClient } from "./apolloClient";
import { GlobalLoadingIndicatorProvider } from "./components/GlobalLoadingIndicator";
import { GlobalDarkModeProvider } from "./components/GlobalDarkMode";

const logRocketAppId = process.env.REACT_APP_LOGROCKET_APP_ID;
const commitHash = process.env.REACT_APP_COMMIT_HASH || "LOCAL";
const nodeEnv = process.env.NODE_ENV;

const App: React.FC<{}> = () => {
	React.useEffect(() => {
		if (logRocketAppId && nodeEnv === "production") {
			LogRocket.init(logRocketAppId, {
				release: commitHash,
			});
			setupLogRocketReact(LogRocket);
		}
	}, []);

	const client = React.useMemo(createApolloClient, []);

	return (
		<ApolloProvider client={client}>
			<Router basename={process.env.PUBLIC_URL}>
				<GlobalDarkModeProvider>
					<CssBaseline />
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<GlobalSnackbarProvider>
							<GlobalLoadingIndicatorProvider>
								<Routes />
							</GlobalLoadingIndicatorProvider>
						</GlobalSnackbarProvider>
					</MuiPickersUtilsProvider>
				</GlobalDarkModeProvider>
			</Router>
		</ApolloProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));
