import React from "react";
import {
	makeStyles,
	Theme,
	TextField as MuiTextField,
	TextFieldProps as MuiTextFieldProps,
	MenuItem,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import clsx from "clsx";

const inputHeight = 40;

interface StyleProps {
	helperTextAlignment: "left" | "center" | "right";
	multiline: boolean;
}
const useStyles = makeStyles((theme: Theme) => ({
	formControlRoot: {
		marginBottom: theme.spacing(4),
	},
	root: ({ multiline }) => ({
		position: "relative",
		backgroundColor: theme.color.grey.light,
		height: multiline ? undefined : theme.typography.pxToRem(inputHeight),
		paddingTop: multiline ? theme.spacing(2) : undefined,
		paddingBottom: multiline ? theme.spacing(2) : undefined,
		"& .MuiInputAdornment-root": {
			color: theme.color.grey.dark,
		},
		"&:focus-within .MuiInputAdornment-root": {
			color: theme.palette.primary.main,
		},
	}),
	error: {},
	label: {
		top: -24,
		fontSize: theme.typography.pxToRem(15),
	},
	labelLoader: {
		width: "100%",
		maxWidth: 150,
		height: theme.typography.pxToRem(12),
	},
	inputRoot: {
		padding: theme.spacing(0, 2, 0, 2),
	},
	input: ({ multiline }: StyleProps) => ({
		height: multiline ? undefined : theme.typography.pxToRem(inputHeight),
	}),
	inputLoader: {
		height: theme.typography.pxToRem(inputHeight),
		margin: theme.spacing(0.5, 0, 0.5, 0),
		width: "100%",
	},
	selectRoot: {
		height: theme.typography.pxToRem(inputHeight),
		display: "inline-block",
	},
	select: {
		padding: theme.spacing(1.25, 3, 1.25, 2),
		width: `calc(100% - ${theme.spacing(5)}px)`,
	},
	helperText: {
		textAlign: ({ helperTextAlignment }: StyleProps) => helperTextAlignment,
	},
	helperTextLoader: {
		width: "100%",
		maxWidth: 300,
		height: theme.typography.pxToRem(12),
	},
	errorText: {
		textAlign: "left",
	},
}));

export type TextFieldProps = Omit<MuiTextFieldProps, "error"> & {
	label?: string;
	error?: string;
	helperText?: string;
	loading?: boolean;
	classes?: ReturnType<typeof useStyles>;
	options?: { value: string | number; label: React.ReactNode }[];
	helperTextAlignment?: "left" | "center" | "right";
};

export const TextField: React.FC<TextFieldProps> = ({
	error,
	classes: classOverrides,
	helperText,
	loading = false,
	InputLabelProps,
	InputProps,
	FormHelperTextProps,
	options,
	helperTextAlignment = "right",
	...textFieldProps
}) => {
	const classes = useStyles({
		helperTextAlignment,
		multiline: textFieldProps.multiline ?? false,
	});

	return loading ? (
		<div
			className={clsx(classes.formControlRoot, classOverrides?.formControlRoot)}
		>
			{textFieldProps.label && <Skeleton className={classes.labelLoader} />}
			<Skeleton className={classes.inputLoader} />
			{(error || helperText) && (
				<Skeleton className={classes.helperTextLoader} />
			)}
		</div>
	) : (
		<MuiTextField
			{...(textFieldProps as MuiTextFieldProps)}
			fullWidth
			type="string"
			classes={{
				root: clsx(classes.formControlRoot, classOverrides?.formControlRoot),
			}}
			InputLabelProps={{
				...InputLabelProps,
				shrink: true,
			}}
			InputProps={{
				...InputProps,
				classes: {
					root: clsx({
						[clsx(classes.root, classOverrides?.root)]: true,
						[clsx(classes.error, classOverrides?.error)]: !!error,
						[clsx(
							classes.inputRoot,
							classOverrides?.inputRoot
						)]: !textFieldProps.select,
						[clsx(
							classes.selectRoot,
							classOverrides?.selectRoot
						)]: textFieldProps.select,
					}),
					input: clsx({
						[clsx(
							classes.input,
							classOverrides?.input
						)]: !textFieldProps.select,
						[clsx(
							classes.select,
							classOverrides?.select
						)]: textFieldProps.select,
					}),
				},
			}}
			FormHelperTextProps={{
				...FormHelperTextProps,
				classes: {
					root: clsx({
						[clsx(classes.helperText, classOverrides?.helperText)]: !error,
						[clsx(classes.errorText, classOverrides?.errorText)]: !!error,
					}),
				},
			}}
			error={!!error}
			helperText={error || helperText}
		>
			{textFieldProps.select &&
				Array.isArray(options) &&
				options.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{label}
					</MenuItem>
				))}
		</MuiTextField>
	);
};
