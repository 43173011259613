import React from "react";
import { RouteComponentProps, Redirect } from "react-router-dom";
import { makeStyles, TextField, Button, Theme } from "@material-ui/core";
import {
	useCurrentUserQuery,
	useLogInMutation,
	CurrentUserDocument,
} from "../../types/graphql-types";
import logo from "../../assets/images/logo.png";
import routes from "../../routes/Routes";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { mapGraphQlError } from "../../utils/errorHandler";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		height: "100vh",
		display: "flex",
		[theme.breakpoints.up(theme.customBreakpoints.lg)]: {
			"&:after": {
				content: `""`,
				display: "block",
				width: "50%",
				height: "100%",
				backgroundColor: theme.color.pink.main,
			},
		},
	},
	formWrapper: {
		padding: theme.spacing(2),
		height: "100%",
		width: "100%",
		display: "flex",
		alignItems: "center",
		[theme.breakpoints.up(theme.customBreakpoints.lg)]: {
			width: "50%",
		},
	},
	logo: {
		width: "100%",
		textAlign: "center",
		marginBottom: theme.spacing(10),
		"& img": {
			width: "100%",
		},
	},
	form: {
		width: "100%",
		margin: "0 auto",
		maxWidth: theme.centralBlockMaxWidth,
	},
	input: {
		marginBottom: theme.spacing(2),
	},
	// TODO: Replace this with a snackbar error message
	error: {
		color: theme.palette.error.main,
	},
	modal: {
		border: "none",
		outline: "none",
	},
	progress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		outline: "none",
	},
}));

export const Login: React.FC<RouteComponentProps<{ redirect?: string }>> = ({
	history,
	location,
}) => {
	const classes = useStyles();
	const currentUser = useCurrentUserQuery({
		fetchPolicy: "network-only",
	});
	const [username, setUsername] = React.useState<string>("");
	const [password, setPassword] = React.useState<string>("");
	const [error, setError] = React.useState<boolean>(false);
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const [login, { loading }] = useLogInMutation({
		refetchQueries: [
			{
				query: CurrentUserDocument,
			},
		],
	});
	const { setSnackbarProps } = useGlobalSnackbar();

	const params = new URLSearchParams(location.search);
	const redirect = params.get("redirect");

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading,
		});
	}, [loading, setLoadingIndicatorProps]);

	if (currentUser.loading) {
		return null;
	} else if (
		currentUser.data?.currentUser?.userId &&
		currentUser.data.currentUser.tokenExpires > Date.now()
	) {
		return <Redirect to={redirect || routes.HOME_PAGE.path} />;
	} else {
		return (
			<div className={classes.root}>
				<div className={classes.formWrapper}>
					<form
						className={classes.form}
						onSubmit={async (e: React.FormEvent<HTMLFormElement>) => {
							e.preventDefault();
							e.stopPropagation();
							const result = await login({
								variables: {
									username,
									password,
								},
							});
							if (result?.data?.logIn?.userId) {
								setSnackbarProps({
									autoHideDuration: 5000,
									open: true,
									success: true,
									message: "Welcome!",
								});
								history.push(redirect || routes.HOME_PAGE.path);
							} else {
								const error =
									result?.errors?.[0] && mapGraphQlError(result.errors[0]);
								setSnackbarProps({
									autoHideDuration: 5000,
									open: true,
									success: false,
									message:
										error?.displayableError || "Invalid username or password!",
								});
								setError(true);
							}
						}}
					>
						<div className={classes.logo}>
							<img src={logo} alt="Heartland Logo" />
						</div>
						<TextField
							name="username"
							label="Username"
							value={username}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
							) => {
								setUsername(e.target.value);
								setError(false);
							}}
							fullWidth
							className={classes.input}
						/>
						<TextField
							type="password"
							name="password"
							label="Password"
							value={password}
							onChange={(
								e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
							) => {
								setPassword(e.target.value);
								setError(false);
							}}
							fullWidth
							className={classes.input}
						/>
						{error && (
							<div className={classes.error}>
								Invalid username or password, please try again
							</div>
						)}
						<Button variant="contained" color="primary" type="submit">
							sign in
						</Button>
					</form>
				</div>
			</div>
		);
	}
};
