import React from "react";
import {
	makeStyles,
	Theme,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import { FeaturedService, Service } from "../../../../types/graphql-types";

const useStyles = makeStyles((theme: Theme) => ({
	field: {
		display: "flex",
	},
	label: {
		fontSize: "0.75rem",
	},
}));

interface ServiceCheckboxProps {
	featuredServices: Array<FeaturedService | null>;
	service: Service;
	enableService: (taxonomyId: string) => void;
	disableService: (taxonoyId: string) => void;
	isParent?: boolean;
}

export const ServiceCheckbox: React.FC<ServiceCheckboxProps> = ({
	featuredServices,
	service,
	enableService,
	disableService,
	isParent,
}) => {
	const classes = useStyles();

	const nonNullFeaturedServices = React.useMemo(() => {
		return featuredServices.filter((s) => !!s) as FeaturedService[];
	}, [featuredServices]);
	const checked = React.useMemo(() => {
		return !!nonNullFeaturedServices.find(
			({ taxonomyId }) => service.taxonomyId === taxonomyId
		);
	}, [nonNullFeaturedServices, service.taxonomyId]);

	return (
		<FormControlLabel
			className={classes.field}
			label={
				<div className={classes.label}>
					{service.text} {isParent ? " (summary)" : ""}
				</div>
			}
			control={
				<Checkbox
					onChange={(e) => {
						if (e.target.checked) {
							enableService(service.taxonomyId);
						} else {
							disableService(service.taxonomyId);
						}
					}}
					checked={checked}
					disabled={!checked && nonNullFeaturedServices.length >= 3}
				/>
			}
		/>
	);
};
