import React, { useMemo } from "react";
import { InputAdornment, makeStyles, Theme } from "@material-ui/core";
import {
	AlertTypes,
	PracticeMetadataDocument,
	PrelaunchHeroImage,
	useEditOfficeMetadataMutation,
	useHomeOptionsQuery,
	usePracticeMetadataQuery,
	useSetHomeOptionsMutation,
	WelcomeBannerWidget,
	WelcomeBannerWidgetFragment,
} from "../../types/graphql-types";
import routes from "../Routes";
import { WidgetNames } from "../../components/WidgetManager/WidgetNames";
import removeTypename from "../../utils/removeTypename";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { TextFieldAdapter } from "../../components/TextField";
import { DateFieldAdapter } from "../../components/DateField";
import { cloneDeep } from "lodash";
import { HeroImageField } from "./HeroImageField";
import { Tooltip } from "../../components/Tooltip";
import PLACEHOLDER_IMAGE from "../../assets/images/placeholder-image.png";
import ContentFooter from "../../components/ContentFooter";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import { mapGraphQlError } from "../../utils/errorHandler";

const DEFAULT_HERO_IMAGE_PUBLIC_ID = "_common/hero/misc/architecture";

const PrelaunchOptionsSchema = Yup.object().shape({
	launchDate: Yup.date()
		.required("Launch date is a required field.")
		.nullable()
		.typeError("Launch date must be a valid date in the format MM/DD/YYYY."),
	publicId: Yup.string().required("You must select a hero image."),
	altText: Yup.string()
		.required("You must include alt text with the hero image.")
		.nullable(),
});

const useStyles = makeStyles((theme: Theme) => ({
	alert: {
		marginBottom: theme.spacing(4),
	},
	adornment: {
		marginRight: 2,
	},
	https: {
		color: `${theme.color.grey.main} !important`,
		marginLeft: 8,
	},
}));

const parseUrlForField = (url?: string | null) => {
	if (url && url !== "") {
		let revisedUrl = url;

		revisedUrl = revisedUrl.replace("https://", "");
		revisedUrl = revisedUrl.replace("http://", "");

		return revisedUrl;
	}

	return "";
};

const addHttpsToUrl = (url?: string | null) => {
	if (url && url !== "") {
		return `https://${url}`;
	}
	return null;
};

const validateUrl = (url?: string | null): string | undefined => {
	try {
		// Parse out any potential https from the user, then add the standard https
		const parsedUrl = addHttpsToUrl(parseUrlForField(url));
		if (parsedUrl) {
			Yup.string().url().validateSync(parsedUrl);
		}
	} catch (error) {
		return "Invalid URL";
	}
	return undefined;
};

interface PrelaunchOptionsFormProps {
	portalId: string;
}

interface PrelaunchOptionsFormValues extends PrelaunchHeroImage {
	launchDate?: string;
}

export const PrelaunchOptionsForm: React.FC<PrelaunchOptionsFormProps> = ({
	portalId,
}) => {
	const classes = useStyles();
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});
	const {
		data: practiceMetadata,
		loading: practiceMetadataLoading,
		error: practiceMetadataError,
	} = usePracticeMetadataQuery({
		variables: {
			portalId,
		},
	});
	const [
		setHomeOptionsMutation,
		setHomeOptionsMutationResponse,
	] = useSetHomeOptionsMutation();
	const [
		editOfficeMetadataMutation,
		editOfficeMetadataMutationResponse,
	] = useEditOfficeMetadataMutation();

	React.useEffect(() => {
		if (error || practiceMetadataError) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Welcome Banner!",
			});
		}
	}, [error, practiceMetadataError, setSnackbarProps]);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading:
				loading ||
				practiceMetadataLoading ||
				setHomeOptionsMutationResponse.loading ||
				editOfficeMetadataMutationResponse.loading,
		});
	}, [
		loading,
		practiceMetadataLoading,
		setHomeOptionsMutationResponse.loading,
		editOfficeMetadataMutationResponse.loading,
		setLoadingIndicatorProps,
	]);

	const homeOptionsResult = useMemo(() => removeTypename(data?.homeOptions), [
		data,
	]);
	const welcomeBannerWidgetIndex = useMemo(
		() =>
			homeOptionsResult?.widgets?.findIndex(
				({ name }) => name === WidgetNames.welcome_banner
			) ?? -1,
		[homeOptionsResult]
	);
	const launchDateResult = useMemo(() => {
		return practiceMetadata?.officeMetadata.launchDate ?? "";
	}, [practiceMetadata]);
	const initialValues = useMemo(() => {
		const welcomeBanner = homeOptionsResult?.widgets?.[
			welcomeBannerWidgetIndex
		] as WelcomeBannerWidgetFragment;
		const prelaunchOptions = removeTypename(
			welcomeBanner?.heroImage?.prelaunch
		);
		return {
			...prelaunchOptions,
			hiringUrl: parseUrlForField(prelaunchOptions?.hiringUrl),
			instagramPost: parseUrlForField(prelaunchOptions?.instagramPost),
			launchDate: launchDateResult,
			publicId: loading
				? PLACEHOLDER_IMAGE
				: prelaunchOptions?.publicId || DEFAULT_HERO_IMAGE_PUBLIC_ID,
		};
	}, [homeOptionsResult, launchDateResult, welcomeBannerWidgetIndex, loading]);

	if (!initialValues) return null;

	return (
		<>
			<Formik<PrelaunchOptionsFormValues>
				initialValues={initialValues}
				validationSchema={PrelaunchOptionsSchema}
				validateOnBlur
				onSubmit={async (values: PrelaunchOptionsFormValues) => {
					const { launchDate, ...prelaunchOptions } = values;
					/**
					 * Mostly just at non-null check here for the compiler's sake, but it's
					 * hypothetically possible that the home options query could throw an
					 * error and then maybe the user somehow still filled out the form
					 */
					if (homeOptionsResult && launchDate) {
						const newHomeOptions = cloneDeep(homeOptionsResult);
						if (
							newHomeOptions &&
							newHomeOptions.widgets &&
							newHomeOptions.widgets[welcomeBannerWidgetIndex] &&
							(newHomeOptions.widgets[
								welcomeBannerWidgetIndex
							] as WelcomeBannerWidgetFragment).heroImage
						) {
							/**
							 * We need the non-null assertion because the compiler's too dumb to
							 * realize we just checked to ensure this value is non-null.
							 */
							// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
							(newHomeOptions.widgets[
								welcomeBannerWidgetIndex
							] as WelcomeBannerWidget).heroImage!.prelaunch = {
								...prelaunchOptions,
								hiringUrl: addHttpsToUrl(prelaunchOptions.hiringUrl),
								instagramPost: addHttpsToUrl(prelaunchOptions.instagramPost),
							};
						}
						const editOfficeResult = await editOfficeMetadataMutation({
							variables: {
								portalId,
								officeMetadata: {
									launchDate,
								},
							},
							refetchQueries: [
								{
									query: PracticeMetadataDocument,
									variables: {
										portalId,
									},
								},
							],
						});
						const setHomeOptionsResult = await setHomeOptionsMutation({
							variables: {
								portalId,
								homeOptions: newHomeOptions,
							},
						});

						if (editOfficeResult?.data && setHomeOptionsResult?.data) {
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: true,
								message: "Prelaunch Options Saved!",
							});
						} else {
							const editOfficeResultError =
								editOfficeResult?.errors?.[0] &&
								mapGraphQlError(editOfficeResult.errors[0]);
							const setHomeOptionsResultError =
								setHomeOptionsResult?.errors?.[0] &&
								mapGraphQlError(setHomeOptionsResult.errors[0]);
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: false,
								message:
									editOfficeResultError?.displayableError ||
									setHomeOptionsResultError?.displayableError ||
									"Prelaunch Options Not Saved!",
							});
						}
					}
				}}
				enableReinitialize
			>
				{(formikBag) => {
					const prelaunchMode =
						formikBag.values.launchDate &&
						new Date(formikBag.values.launchDate) > new Date();
					return (
						<>
							<ContentHeader
								title={routes.PRELAUNCH_OPTIONS.title}
								button={
									<SaveButton
										form="prelaunch-options-form"
										disabled={!formikBag.isValid}
									/>
								}
							/>
							<Form id="prelaunch-options-form">
								<Field
									name="launchDate"
									label="Launch Date"
									format="MM/dd/yyyy"
									helperText="The date that this office will be open for public appointments. Prelaunch mode will self-disable on this date and fallback to your chosen welcome banner configuration."
									helperTextAlignment="left"
									component={DateFieldAdapter}
								/>
								{!prelaunchMode && (
									<Tooltip
										className={classes.alert}
										type={AlertTypes.Info}
										text="Prelaunch mode is currently disabled. To set the prelaunch layout options, set the Office Launch Date to a date in the future."
									/>
								)}
								<Field
									name="publicId"
									label="Hero Image"
									disabled={!prelaunchMode}
									portalId={portalId}
									component={HeroImageField}
								/>
								<Field
									name="altText"
									label="Alt Text"
									helperText="Required: Alt Text for the selected hero image. Must be provided for accessibility."
									helperTextAlignment="left"
									disabled={!prelaunchMode}
									component={TextFieldAdapter}
								/>
								<Field
									name="text"
									label="Text"
									helperText="Optional: A short text blurb to display underneath the prelaunch welcome banner."
									helperTextAlignment="left"
									multiline
									disabled={!prelaunchMode}
									component={TextFieldAdapter}
								/>
								<Field
									name="hiringUrl"
									label="Hiring URL"
									helperText="Optional: The URL to this practice's online hiring site."
									helperTextAlignment="left"
									disabled={!prelaunchMode}
									component={TextFieldAdapter}
									validate={validateUrl}
									InputProps={{
										startAdornment: (
											<InputAdornment
												position="start"
												className={classes.adornment}
											>
												<div className={classes.https}>https://</div>
											</InputAdornment>
										),
										onBlur: () => {
											formikBag.setFieldValue(
												"hiringUrl",
												parseUrlForField(
													formikBag.getFieldProps("hiringUrl").value
												)
											);
										},
										onChange: (e) => {
											formikBag.setFieldValue(
												"hiringUrl",
												parseUrlForField(e.target.value)
											);
										},
									}}
								/>
								<Field
									name="instagramPost"
									label="Instagram Post"
									helperText="Optional: An Instagram post URL to feature on the homepage."
									helperTextAlignment="left"
									disabled={!prelaunchMode}
									component={TextFieldAdapter}
									validate={validateUrl}
									InputProps={{
										startAdornment: (
											<InputAdornment
												position="start"
												className={classes.adornment}
											>
												<div className={classes.https}>https://</div>
											</InputAdornment>
										),
										onBlur: () => {
											formikBag.setFieldValue(
												"instagramPost",
												parseUrlForField(
													formikBag.getFieldProps("instagramPost").value
												)
											);
										},
										onChange: (e) => {
											formikBag.setFieldValue(
												"instagramPost",
												parseUrlForField(e.target.value)
											);
										},
									}}
								/>
							</Form>
							<ContentFooter>
								<SaveButton
									form="prelaunch-options-form"
									disabled={!formikBag.isValid}
								/>
							</ContentFooter>
						</>
					);
				}}
			</Formik>
		</>
	);
};
