import React from "react";
import { SvgIconProps, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
	outerCircle: {
		fill: theme.color.grey.main,
	},
	innerCircle: {
		fill: theme.color.white.main,
	},
	arrows: {
		fill: theme.color.grey.main,
	},
}));

export const HeightCircle = (props: SvgIconProps) => {
	const classes = useStyles();
	return (
		<svg
			{...props}
			focusable="false"
			viewBox="0 0 24 24"
			aria-hidden="true"
			role="presentation"
		>
			<circle cx="12" cy="12" r="12" className={classes.outerCircle} />
			<circle cx="11.995" cy="12" r="10.007" className={classes.innerCircle} />
			<path
				d="M13,6.99l3,0l-4,-3.99l-4,3.99l3,0l0,10.02l-3,0l4,3.99l4,-3.99l-3,0l0,-10.02Z"
				className={classes.arrows}
			/>
		</svg>
	);
};
