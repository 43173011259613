import React, { ChangeEvent } from "react";
import Highlight from "react-highlighter";
import { NavLink, useHistory } from "react-router-dom";
import { useDebounce } from "use-debounce";

import {
	makeStyles,
	Typography,
	TextField,
	InputAdornment,
	Theme,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import {
	useOfficeSearchQuery,
	OfficeSearchResult,
	Maybe,
} from "../../types/graphql-types";

import { setOffices } from "../../utils/RecentOffices";
import routes from "../../routes/Routes";

const useStyles = makeStyles((theme: Theme) => ({
	search: {
		alignItems: "center",
		backgroundColor: theme.color.grey.light,
		borderRadius: 4,
		display: "flex",
		flex: 1,
		padding: theme.spacing(1.5, 1.5, 1.5, 0),
		position: "relative",
	},
	search_input: {
		width: "100%",
	},
	adornment: {
		margin: theme.spacing(0, 1),
	},
	icon: {
		color: theme.color.grey.main,
		cursor: "pointer",
	},
	list_item: {
		padding: 0,
		margin: 0,
	},
	link: {
		color: theme.color.grey.dark,
		fontSize: "0.9rem",
		marginLeft: theme.spacing(2),
	},
	dropDown: {
		borderRadius: theme.shape.borderRadius,
		position: "absolute",
		top: 41,
		zIndex: 200,
		boxSizing: "border-box",
		maxWidth: theme.centralBlockMaxWidth,
		width: "100%",
		minWidth: "100%",
		backgroundColor: theme.color.grey.light,
		listStyleType: "none",
		padding: theme.spacing(3),
	},
	title: {
		fontSize: theme.spacing(2),
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		"& *": {
			color: theme.color.grey.dark,
		},
	},
	item: {
		borderTop: `1px solid ${theme.color.grey.main}`,
		cursor: "pointer",
		display: "block",
		paddingBottom: theme.spacing(2),
		paddingTop: theme.spacing(2),
		paddingRight: theme.spacing(2),
		color: "unset",
		textDecoration: "none",

		"&:first-of-type": {
			border: "none",
			paddingTop: 0,
		},

		"&:last-child": {
			paddingBottom: 0,
		},
	},
	highlight: {
		backgroundColor: "transparent",
		fontWeight: "bold",
	},
}));

export type OfficeSearchProps = {
	inNav: boolean;
};

export const OfficeSearch: React.FC<OfficeSearchProps> = ({ inNav }) => {
	const classes = useStyles();
	const history = useHistory();
	const [searchQuery, setSearchQuery] = React.useState<string>("");
	const [debouncedSearchQuery] = useDebounce(searchQuery, 200);
	const [dropdownOpen, setDropdownOpen] = React.useState<boolean>(false);
	const [officeSelected, setOfficeSelected] = React.useState<
		Maybe<OfficeSearchResult>
	>(null);
	const searchField = React.useRef<HTMLDivElement>();
	const officeSearch = useOfficeSearchQuery({
		variables: {
			query: debouncedSearchQuery,
		},
		skip: !debouncedSearchQuery,
	});

	React.useEffect(() => {
		if (officeSearch.data) {
			setDropdownOpen(true);
		}
	}, [officeSearch]);

	React.useEffect(() => {
		if (officeSelected) {
			searchField.current?.blur();
			setSearchQuery("");
			setOffices(officeSelected);
			setDropdownOpen(false);
			history.push(`/${officeSelected.portalId}`);
		}
	}, [officeSelected, history]);

	return (
		<React.Fragment>
			<div className={classes.search} data-testid="office_search_field_wrapper">
				<TextField
					id="office_search_field"
					autoComplete="off"
					name="searchQuery"
					value={searchQuery}
					placeholder="Search by Office Name..."
					className={classes.search_input}
					ref={(el) => el && (searchField.current = el)}
					onChange={(
						e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
					) => {
						setSearchQuery(e.target.value);
					}}
					onFocus={() => {
						setDropdownOpen(true);
					}}
					onBlur={() => {
						setDropdownOpen(false);
					}}
					InputProps={{
						disableUnderline: true,
						startAdornment: (
							<InputAdornment position="start" className={classes.adornment}>
								<SearchIcon className={classes.icon} />
							</InputAdornment>
						),
					}}
					inputProps={{
						"data-testid": "office_search_field",
					}}
				/>
				{dropdownOpen && (officeSearch.data?.officeSearch.length || 0) > 0 && (
					<ul className={classes.dropDown} data-testid="office_search_dropdown">
						{officeSearch.data?.officeSearch.map(
							(office: OfficeSearchResult) => (
								<NavLink
									key={office.portalId}
									to={routes.DASHBOARD.toPath({ portalId: office.portalId })}
									className={classes.item}
									onMouseDown={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setOfficeSelected({ ...office });
									}}
								>
									<li className={classes.list_item}>
										<div className={classes.title}>
											<Highlight
												search={searchQuery}
												matchClass={classes.highlight}
											>
												{office.officeName}
											</Highlight>
										</div>
										<Typography className={classes.link}>
											{office.contentUrl}
										</Typography>
									</li>
								</NavLink>
							)
						)}
					</ul>
				)}
			</div>
		</React.Fragment>
	);
};
