import React from "react";
import { Field, FieldProps } from "formik";
import {
	makeStyles,
	Typography,
	RadioGroup,
	FormControlLabel,
	Radio,
	Theme,
} from "@material-ui/core";
import { ModalProps, formatWidgetName } from "../utils";
import { GoogleWidgetOptions } from "../../../types/graphql-types";
import googleBlock from "../../../assets/images/google-block-default.png";
import googleBlockTour from "../../../assets/images/google-block-alt.png";
import googleBlockDir from "../../../assets/images/google-block-50.png";
import { Tooltip, TooltipTypes } from "../../../components/Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
	header: {
		marginBottom: theme.spacing(4),
	},
	radio: {
		alignItems: "start",

		"& > .MuiFormControlLabel-label": {
			marginTop: 10,
			width: "100%",
		},
	},
	radioLabel: {
		width: "100%",
	},
	radioName: {
		marginBottom: 4,
	},
	secondary_text: {
		fontSize: "0.75rem",
		marginBottom: theme.spacing(2),
	},
	tooltip: {
		marginLeft: theme.spacing(6),
		marginRight: theme.spacing(6),
		marginBottom: theme.spacing(2),
	},
	radioImage: {
		textAlign: "center",
	},
}));

const OptionMappings = {
	[GoogleWidgetOptions.Map]: {
		imgSrc: googleBlock,
		text: "This is the standard Google Map option.",
	},
	[GoogleWidgetOptions.VirtualTour]: {
		imgSrc: googleBlockTour,
		text:
			"This option adds a virtual tour component to the Google Maps widget.",
	},
	[GoogleWidgetOptions.FiftyFifty]: {
		imgSrc: googleBlockDir,
		text: "This option adds a directions component to the Google Map widget.",
		tooltipText: "The directions data comes from GEMS.",
	},
};

export const GoogleBlockModal: React.FC<ModalProps> = ({ widget, index }) => {
	const classes = useStyles();

	return (
		<div>
			<Typography variant="h5" className={classes.header}>
				{formatWidgetName(widget.name)} Widget Options
			</Typography>
			<Field name={`widgets.${index}.option`}>
				{({ field }: FieldProps) => (
					<RadioGroup {...field}>
						{Object.keys(GoogleWidgetOptions).map((option: string) => (
							<FormControlLabel
								key={option}
								value={GoogleWidgetOptions[option]}
								control={<Radio />}
								className={classes.radio}
								label={
									<div className={classes.radioLabel}>
										<div className={classes.radioName}>
											{GoogleWidgetOptions[option] ===
											GoogleWidgetOptions.FiftyFifty
												? "Google Map 50/50 with Directions"
												: GoogleWidgetOptions[option] ===
												  GoogleWidgetOptions.VirtualTour
												? "Google Map with Virtual Tour"
												: "Google Map (default)"}
										</div>
										<div className={classes.secondary_text}>
											<em>
												{OptionMappings[GoogleWidgetOptions[option]].text}
											</em>
										</div>
										{OptionMappings[GoogleWidgetOptions[option]]
											.tooltipText && (
											<div className={classes.tooltip}>
												<Tooltip
													type={TooltipTypes.Info}
													text={
														OptionMappings[GoogleWidgetOptions[option]]
															.tooltipText
													}
												/>
											</div>
										)}

										<div className={classes.radioImage}>
											<img
												src={OptionMappings[GoogleWidgetOptions[option]].imgSrc}
												alt={option}
											/>
										</div>
									</div>
								}
							/>
						))}
					</RadioGroup>
				)}
			</Field>
		</div>
	);
};
