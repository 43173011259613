import React from "react";
import clsx from "clsx";
import { convertFromRaw, Editor, EditorState } from "draft-js";
import { makeStyles, Theme } from "@material-ui/core";
import { customBlockRenderMap } from "../RichTextEditor/renderUtils";
import { deserialize } from "../RichTextEditor";

const useStyles = makeStyles((theme: Theme) => ({
	editor: {
		padding: theme.spacing(2),
		// Separate side-by-side paragraphs with brief space for legibility
		"& div[data-block='true'] + div[data-block='true']": {
			marginTop: theme.spacing(2),
		},
	},
	loader: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
}));

interface RichTextViewerProps {
	value: string | null | undefined;
	className?: string;
}

export const RichTextViewer: React.FC<RichTextViewerProps> = ({
	value,
	className,
}) => {
	const classes = useStyles();
	const editor = React.useRef<Editor | null>(null);
	const editorState = React.useMemo(() => {
		try {
			// see if it's json; it'll throw an error otherwise
			const dataJson = JSON.parse(value || "");

			return EditorState.createWithContent(convertFromRaw(dataJson));
		} catch (ex) {
			// not json, so deserialize
			return deserialize(value);
		}
	}, [value]);

	return (
		<div
			className={clsx(classes.editor, className)}
			onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
				// Prevent in-editor clicks from triggering the background focus handler
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<Editor
				ref={(ref) => (editor.current = ref)}
				editorState={editorState}
				onChange={() => {
					return null;
				}}
				blockRenderMap={customBlockRenderMap}
				readOnly={true}
			/>
		</div>
	);
};
