import React from "react";

import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import standardImg from "../../assets/images/footer-standard.png";
import mapImage from "../../assets/images/footer-map.png";
import {
	makeStyles,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	Theme,
} from "@material-ui/core";

import routes, { ProtectedRouteParams } from "../Routes";
import {
	useHomeOptionsQuery,
	useSetHomeOptionsMutation,
	HomeOptionsInput,
	HomeOptionsDocument,
	FooterTypes,
} from "../../types/graphql-types";
import { Formik, Form, Field, FieldProps } from "formik";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { mapGraphQlError } from "../../utils/errorHandler";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import ContentFooter from "../../components/ContentFooter";
import removeTypename from "../../utils/removeTypename";

const useStyles = makeStyles((theme: Theme) => ({
	group: {
		borderBottom: `1px solid ${theme.color.grey.main}`,
		paddingLeft: theme.spacing(2),
		marginBottom: theme.spacing(4),
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
	},
	radio: {
		marginBottom: theme.spacing(3),
	},
	radioLabel: {
		display: "flex",
		flexDirection: "column",
	},
	radioImage: {
		width: 150,
	},
	radioTextArea: {
		marginLeft: theme.spacing(2),
	},
	radioTextHead: {
		marginBottom: theme.spacing(0.5),
		marginTop: theme.spacing(2),
	},
	description: {
		fontSize: "0.75rem",
	},
	imageStandardsLink: {
		marginLeft: theme.spacing(0.5),
	},
	standardFooter: {},
	googleMapFooter: {},
	footerImg: {},
	subheader: {
		marginBottom: theme.spacing(4),
	},
}));

export const Footer: React.FC<RouteComponentProps<ProtectedRouteParams>> = ({
	match,
}) => {
	const classes = useStyles();
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Footer Options!",
			});
		}
	}, [error, setSnackbarProps]);

	const [
		setHomeOptionsMutation,
		setHomeOptionsMutationResponse,
	] = useSetHomeOptionsMutation();

	const homeOptionsResult = data?.homeOptions;

	const initialValues = removeTypename(homeOptionsResult);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || setHomeOptionsMutationResponse.loading,
		});
	}, [
		loading,
		setHomeOptionsMutationResponse.loading,
		setLoadingIndicatorProps,
	]);

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title={routes.FOOTER.title}
				button={<SaveButton form={"footer-form"} />}
			/>
			{!initialValues ? null : (
				<Formik<HomeOptionsInput>
					initialValues={initialValues}
					onSubmit={async (homeOptions: HomeOptionsInput) => {
						const result = await setHomeOptionsMutation({
							variables: {
								portalId,
								homeOptions,
							},
							refetchQueries: [
								{
									query: HomeOptionsDocument,
									variables: {
										portalId: portalId,
									},
								},
							],
							awaitRefetchQueries: true,
						});
						if (result?.data) {
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: true,
								message: "Footer Options Saved!",
							});
						} else {
							const error =
								result?.errors?.[0] && mapGraphQlError(result.errors[0]);
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: false,
								message: error?.displayableError || "Footer Options Not Saved!",
							});
						}
					}}
					enableReinitialize={true}
				>
					<Form id="footer-form">
						<Typography variant="h6" className={classes.subheader}>
							Select Footer Style
						</Typography>
						<Field name="footer">
							{({ field }: FieldProps) => (
								<RadioGroup className={classes.group} {...field}>
									{Object.keys(FooterTypes).map((footerType: FooterTypes) => (
										<FormControlLabel
											key={footerType}
											value={FooterTypes[footerType]}
											control={<Radio />}
											className={classes.radio}
											labelPlacement="top"
											label={
												<div className={classes.radioLabel}>
													<div className={classes.radioTextArea}>
														<h5 className={classes.radioTextHead}>
															{footerType}
														</h5>
													</div>
													<div
														className={
															FooterTypes[footerType] === FooterTypes.Map
																? classes.googleMapFooter
																: classes.standardFooter
														}
													>
														<img
															src={
																FooterTypes[footerType] === FooterTypes.Map
																	? mapImage
																	: standardImg
															}
															alt={
																FooterTypes[footerType] === FooterTypes.Map
																	? "map footer stand in"
																	: "default footer stand in"
															}
														/>
													</div>
												</div>
											}
										/>
									))}
								</RadioGroup>
							)}
						</Field>
					</Form>
				</Formik>
			)}
			<ContentFooter>
				<SaveButton form={"footer-form"} />
			</ContentFooter>
		</Layout>
	);
};
