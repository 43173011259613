import React from "react";
import { SvgIconProps } from "@material-ui/core";

export const SnapchatIcon = (props: SvgIconProps) => {
	return (
		<svg
			{...props}
			className="MuiSvgIcon-root"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			width="24px"
			height="24px"
		>
			{" "}
			<path d="M12,2C6,2,6,8,6,8v1c0,0-0.023-0.001-0.063-0.001C5.597,8.999,4,9.069,4,10.395C4,11.94,5.697,12,5.965,12 C5.988,12,6,12,6,12c0,1-1.652,2.171-2.736,2.442c-1.01,0.392-1.251,1.061-1.264,1.568c-0.008,0.303,0.066,0.548,0.106,0.643 c0.377,0.885,1.497,1.342,2.594,1.342C4.738,19.434,5.776,20,6.471,20c0.794,0,1.634-0.388,1.634-0.388C8.146,19.646,9.425,21,12,21 s3.854-1.354,3.896-1.388c0,0,0.839,0.388,1.634,0.388c0.695,0,1.732-0.566,1.77-2.005c1.097,0,2.217-0.457,2.594-1.342 c0.04-0.095,0.114-0.34,0.106-0.643c-0.013-0.507-0.254-1.176-1.264-1.568C19.652,14.171,18,13,18,12c0,0,0.012,0,0.035,0 C18.303,12,20,11.94,20,10.395c0-1.326-1.597-1.396-1.937-1.396C18.023,8.999,18,9,18,9V8C18,8,18,2,12,2L12,2z" />
		</svg>
	);
};
