import React from "react";

import { Layout } from "../../components/Layout";
import { RouteComponentProps } from "react-router-dom";
import {
	makeStyles,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
	Theme,
} from "@material-ui/core";

import routes, { ProtectedRouteParams } from "../Routes";
import {
	useHomeOptionsQuery,
	useSetHomeOptionsMutation,
	HomeOptionsInput,
	Colors,
	ButtonStyles,
	HomeOptionsDocument,
} from "../../types/graphql-types";
import { Formik, Form, Field, FieldProps } from "formik";
import { useGlobalSnackbar } from "../../components/GlobalSnackbar";
import { useGlobalLoadingIndicator } from "../../components/GlobalLoadingIndicator";
import { UseGlobalDarkModeContext } from "../../components/GlobalDarkMode";
import { mapGraphQlError } from "../../utils/errorHandler";
import SaveButton from "../../components/SaveButton";
import ContentHeader from "../../components/ContentHeader";
import ContentFooter from "../../components/ContentFooter";
import removeTypename from "../../utils/removeTypename";

interface ThemeProps {
	darkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	group: {
		borderBottom: `1px solid ${theme.color.grey.main}`,
		paddingLeft: theme.spacing(2),
		marginBottom: theme.spacing(4),
	},
	radio: {
		marginBottom: theme.spacing(3),
	},
	radioLabel: {
		display: "flex",
		marginLeft: theme.spacing(2),
	},
	radioImage: {
		width: 150,
	},
	radioTextArea: {
		marginLeft: theme.spacing(2),
	},
	radioTextHead: {
		marginBottom: theme.spacing(0.5),
		marginTop: theme.spacing(2),
	},
	description: {
		fontSize: "0.75rem",
	},
	imageStandardsLink: {
		marginLeft: theme.spacing(0.5),
	},
	white: ({ darkMode }: ThemeProps) => ({
		border: "1px solid black",
		width: theme.spacing(6.25),
		height: theme.spacing(6.25),
		backgroundColor: darkMode ? theme.color.black.main : theme.color.white.main,
	}),
	sand: {
		border: "1px solid black",
		width: theme.spacing(6.25),
		height: theme.spacing(6.25),
		backgroundColor: theme.color.sand.main,
	},
	stone: {
		border: "1px solid black",
		width: theme.spacing(6.25),
		height: theme.spacing(6.25),
		backgroundColor: theme.color.stone.main,
	},
	subheader: {
		marginBottom: theme.spacing(4),
	},
	tab: {
		boxShadow: `0px 2px 2px 0px ${theme.color.teal.dark}`,
		backgroundColor: theme.color.teal.dark,
		color: theme.palette.primary.contrastText,
		padding: `1rem 0`,
		textAlign: "center",
		width: 134,
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
	},
	pill: {
		boxShadow: `0px 2px 2px 0px ${theme.color.teal.dark}`,
		backgroundColor: theme.color.teal.dark,
		color: theme.palette.primary.contrastText,
		padding: `1rem 0`,
		textAlign: "center",
		width: 134,
		borderRadius: 100,
	},
	block: {
		boxShadow: `0px 2px 2px 0px ${theme.color.teal.dark}`,
		backgroundColor: theme.color.teal.dark,
		color: theme.palette.primary.contrastText,
		padding: `1rem 0`,
		textAlign: "center",
		width: 134,
	},
}));

export const ColorsAndStyles: React.FC<
	RouteComponentProps<ProtectedRouteParams>
> = ({ match }) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });
	const portalId = match?.params?.portalId?.toString() || "";
	const { setLoadingIndicatorProps } = useGlobalLoadingIndicator();
	const { setSnackbarProps } = useGlobalSnackbar();
	const { data, loading, error } = useHomeOptionsQuery({
		variables: {
			portalId,
		},
	});

	React.useEffect(() => {
		if (error) {
			setSnackbarProps({
				autoHideDuration: 5000,
				open: true,
				success: false,
				message: "Failed to fetch Colors and Styles!",
			});
		}
	}, [error, setSnackbarProps]);

	const [
		setHomeOptionsMutation,
		setHomeOptionsMutationResponse,
	] = useSetHomeOptionsMutation();

	const homeOptionsResult = data?.homeOptions;

	const initialValues = removeTypename(homeOptionsResult);

	React.useEffect(() => {
		setLoadingIndicatorProps({
			loading: loading || setHomeOptionsMutationResponse.loading,
		});
	}, [
		loading,
		setHomeOptionsMutationResponse.loading,
		setLoadingIndicatorProps,
	]);

	return (
		<Layout portalId={match.params.portalId}>
			<ContentHeader
				title={routes.COLORS_AND_STYLES.title}
				button={<SaveButton form={"colors-and-styles-form"} />}
			/>
			{!initialValues ? null : (
				<Formik<HomeOptionsInput>
					initialValues={initialValues}
					onSubmit={async (homeOptions: HomeOptionsInput) => {
						const result = await setHomeOptionsMutation({
							variables: {
								portalId,
								homeOptions,
							},
							refetchQueries: [
								{
									query: HomeOptionsDocument,
									variables: {
										portalId: portalId,
									},
								},
							],
							awaitRefetchQueries: true,
						});
						if (result?.data) {
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: true,
								message: "Colors and Styles Saved!",
							});
						} else {
							const error =
								result?.errors?.[0] && mapGraphQlError(result.errors[0]);
							setSnackbarProps({
								autoHideDuration: 5000,
								open: true,
								success: false,
								message:
									error?.displayableError || "Colors and Styles Not Saved!",
							});
						}
					}}
					enableReinitialize={true}
				>
					<Form id="colors-and-styles-form">
						<Typography variant="h6" className={classes.subheader}>
							Select Accent Color
						</Typography>
						<Field name="accentColor">
							{({ field }: FieldProps) => (
								<RadioGroup className={classes.group} {...field}>
									{Object.keys(Colors).map((color: string) => (
										<FormControlLabel
											key={color}
											value={Colors[color]}
											control={<Radio />}
											className={classes.radio}
											label={
												<div className={classes.radioLabel}>
													<div
														className={
															Colors[color] === Colors.White
																? classes.white
																: Colors[color] === Colors.Sand
																? classes.sand
																: classes.stone
														}
													></div>
													<div className={classes.radioTextArea}>
														<h5 className={classes.radioTextHead}>
															{Colors[color] === Colors.White
																? `${color} (default)`
																: color}
														</h5>
													</div>
												</div>
											}
										/>
									))}
								</RadioGroup>
							)}
						</Field>

						<Typography variant="h6" className={classes.subheader}>
							Select Button Style
						</Typography>
						<Field name="buttonStyle">
							{({ field }: FieldProps) => (
								<RadioGroup className={classes.group} {...field}>
									{Object.keys(ButtonStyles).map((button: string) => (
										<FormControlLabel
											key={button}
											value={ButtonStyles[button]}
											control={<Radio />}
											className={classes.radio}
											label={
												<div className={classes.radioLabel}>
													<div
														className={
															ButtonStyles[button] === ButtonStyles.Block
																? classes.block
																: ButtonStyles[button] === ButtonStyles.Pill
																? classes.pill
																: classes.tab
														}
													>
														Button 1
													</div>
													<div className={classes.radioTextArea}>
														<h5 className={classes.radioTextHead}>
															{ButtonStyles[button] === ButtonStyles.Tab
																? `${button} (default)`
																: button}
														</h5>
													</div>
												</div>
											}
										/>
									))}
								</RadioGroup>
							)}
						</Field>
					</Form>
				</Formik>
			)}
			<ContentFooter>
				<SaveButton form={"colors-and-styles-form"} />
			</ContentFooter>
		</Layout>
	);
};
