import React from "react";
import clsx from "clsx";
import {
	Editor,
	EditorState,
	EditorProps,
	RichUtils,
	SelectionState,
} from "draft-js";
import { makeStyles } from "@material-ui/core/styles";
import {
	Theme,
	IconButton,
	Button,
	CircularProgress,
	InputLabel,
} from "@material-ui/core";
import BoldIcon from "@material-ui/icons/FormatBold";
import ItalicIcon from "@material-ui/icons/FormatItalic";
import { HeaderIcon } from "../../icons";
import BulletedListIcon from "@material-ui/icons/FormatListBulleted";
import UndoIcon from "@material-ui/icons/Undo";
import RedoIcon from "@material-ui/icons/Redo";
import { customBlockRenderMap, Style, BlockType } from "./renderUtils";
import { getCurrentBlockType, isSoftNewlineEvent } from "./dataUtils";
import { Counter, CounterType } from "./Counter";

const HANDLED = "handled";
const NOT_HANDLED = "not-handled";

interface RichTextStylesProps {
	maxEditorHeight?: number;
}
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		backgroundColor: theme.color.grey.light,
		borderBottom: `1px solid ${theme.color.grey.dark}`,
		margin: 0,
		cursor: "text",
	},
	error: {
		"& $toolbar": {
			border: "none",
			borderBottom: `1px solid ${theme.palette.error.dark}`,
		},
		border: `1px solid ${theme.palette.error.dark}`,
	},
	loading: {
		opacity: 0.3,
		pointerEvents: "none",
	},
	loader: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	editor: {
		maxHeight: ({ maxEditorHeight }: RichTextStylesProps) =>
			maxEditorHeight || "initial",
		overflowY: ({ maxEditorHeight }: RichTextStylesProps) =>
			maxEditorHeight ? "auto" : "initial",
		padding: theme.spacing(2),
		// Separate side-by-side paragraphs with brief space for legibility
		"& div[data-block='true'] + div[data-block='true']": {
			marginTop: theme.spacing(2),
		},
	},
	toolbar: {
		border: `1px solid ${theme.color.grey.dark}`,
		display: "flex",
		cursor: "initial",
	},
	styleButtons: {
		flexGrow: 1,
	},
	undoRedoButtons: {
		flexShrink: 1,
	},
}));

interface RichTextEditorProps {
	/**
	 * The pre-initialized DraftJS editor state.
	 *
	 * When coming from an HTML string, use the `deserialize` function.
	 *
	 * To instantiate an empty editor state, use `EditorState.createEmpty()`.
	 *
	 * @see https://draftjs.org/docs/api-reference-editor-state/
	 */
	value: EditorState;
	onChange: (editorState: EditorState) => void;
	onBlur?: () => void;
	label?: string;
	error?: boolean;
	loading?: boolean;
	className?: string;
	/**
	 * A plaintext (non-HTML, non DraftJS) string to use as a placeholder
	 */
	placeholder?: string;
	/**
	 * The character limit, if any.
	 *
	 * To display the character count as a counter, use `Infinity`.
	 *
	 * To display the count as a countdown of remaining characters, use a number.
	 *
	 * To hide character count info, simply don't use this prop.
	 *
	 * **Note:** This does NOT do any form validation. It only displays the count
	 * to the user. To validate the field, use the `charCountValidator`, or the
	 * `getCharCount(contentState)` function.
	 */
	charLimit?: number;
	/**
	 * The word limit, if any.
	 *
	 * To display the word count as a counter, use `Infinity`.
	 *
	 * To display the count as a countdown of remaining word, use a number.
	 *
	 * To hide word count info, simply don't use this prop.
	 *
	 * **Note:** This does NOT do any form validation. It only displays the count
	 * to the user. To validate the field, use the `wordCountValidator`, or the
	 * `getWordCount(contentState)` function.
	 */
	wordLimit?: number;
	/**
	 * The line limit, if any.
	 *
	 * To display the line count as a counter, use `Infinity`.
	 *
	 * To display the count as a countdown of remaining lines, use a number.
	 *
	 * To hide line count info, simply don't use this prop.
	 *
	 * **Note:** This does NOT do any form validation. It only displays the count
	 * to the user. To validate the field, use the `lineCountValidator`, or the
	 * `getLineCount(contentState)` function.
	 */
	lineLimit?: number;
	/**
	 * The maximum permissable height for the editor. If not provided, the editor
	 * has no maximum height.
	 */
	maxEditorHeight?: number;
	/**
	 * The formatting options rendered in the editor
	 *
	 * Each value set to true will render the corresponding formatting option
	 * in the editor
	 *
	 */
	formattingOptions?: {
		header?: boolean;
		bulleted?: boolean;
		bold?: boolean;
		Italic?: boolean;
	};
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
	value: editorState,
	onChange,
	onBlur,
	label,
	error,
	loading,
	className,
	placeholder,
	charLimit,
	wordLimit,
	lineLimit,
	maxEditorHeight,
	formattingOptions,
}) => {
	const classes = useStyles({ maxEditorHeight });
	const editor = React.useRef<Editor | null>(null);
	const [focused, setFocused] = React.useState<boolean>(false);
	const renderedOptions = formattingOptions
		? {
				header: !!formattingOptions.header,
				bulleted: !!formattingOptions.bulleted,
				bold: !!formattingOptions.bold,
				Italic: !!formattingOptions.Italic,
		  }
		: false;

	const contentState = editorState.getCurrentContent();

	const blockType = React.useMemo(() => getCurrentBlockType(editorState), [
		editorState,
	]);

	const currentInlineStyle = React.useMemo(
		() => editorState.getCurrentInlineStyle(),
		[editorState]
	);

	const undoStack = React.useMemo(() => editorState.getUndoStack(), [
		editorState,
	]);
	const redoStack = React.useMemo(() => editorState.getRedoStack(), [
		editorState,
	]);

	const handleKeyCommand: EditorProps["handleKeyCommand"] = (
		command,
		editorState
	) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);

		if (newState) {
			onChange(newState);
			return HANDLED;
		}

		return NOT_HANDLED;
	};

	const handleReturn = React.useCallback(
		(e: React.KeyboardEvent<{}>, editorState: EditorState) => {
			const selectionState = editorState.getSelection();
			const contentState = editorState.getCurrentContent();
			const currentBlock = contentState.getBlockForKey(
				selectionState.getStartKey()
			);

			if (isSoftNewlineEvent(e)) {
				onChange(RichUtils.insertSoftNewline(editorState));
				return HANDLED;
			}

			if (!e.altKey && !e.metaKey && !e.ctrlKey) {
				if (currentBlock.getLength() === 0) {
					switch (blockType) {
						case BlockType.Header:
						case BlockType.Bulleted:
							onChange(
								RichUtils.toggleBlockType(editorState, BlockType.Paragraph)
							);
							return HANDLED;
						default:
							return NOT_HANDLED;
					}
				}
			}
			return NOT_HANDLED;
		},
		[blockType, onChange]
	);

	const handleUndo = () => {
		onChange(EditorState.undo(editorState));
	};

	const handleUndoAll = () => {
		let newEditorState = editorState;
		for (let i = 0; i < undoStack.size; i++) {
			newEditorState = EditorState.undo(newEditorState);
		}
		onChange(newEditorState);
	};

	const handleRedo = () => {
		onChange(EditorState.redo(editorState));
	};

	const toggleInlineStyle = (style: Style) => {
		onChange(RichUtils.toggleInlineStyle(editorState, style));
	};

	const toggleBlockType = (blockType: BlockType) => {
		onChange(RichUtils.toggleBlockType(editorState, blockType));
	};

	return (
		<>
			{label && (
				<InputLabel shrink error={error}>
					{label}
				</InputLabel>
			)}
			<div
				className={clsx(
					classes.root,
					className,
					error && classes.error,
					loading && classes.loading
				)}
				onClick={() => {
					editor.current?.focus();
					setFocused(true);
					const lastBlock = editorState
						.getCurrentContent()
						.getBlockMap()
						.last();
					const key = lastBlock.getKey();
					const length = lastBlock.getLength();
					const selection = new SelectionState({
						anchorKey: key,
						anchorOffset: length,
						focusKey: key,
						focusOffset: length,
					});
					onChange(EditorState.forceSelection(editorState, selection));
				}}
			>
				{loading && (
					<div className={classes.loader}>
						<CircularProgress />
					</div>
				)}
				<div
					className={classes.toolbar}
					onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
						// Prevent toolbar clicks from triggering the background focus handler
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<div className={classes.styleButtons}>
						{(!renderedOptions || renderedOptions.header) && (
							<IconButton
								color={
									focused && blockType === BlockType.Header
										? "primary"
										: "default"
								}
								onMouseDown={(
									e: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									e.preventDefault();
									toggleBlockType(BlockType.Header);
								}}
							>
								<HeaderIcon />
							</IconButton>
						)}
						{(!renderedOptions || renderedOptions.bulleted) && (
							<IconButton
								color={
									focused && blockType === BlockType.Bulleted
										? "primary"
										: "default"
								}
								onMouseDown={(
									e: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									e.preventDefault();
									toggleBlockType(BlockType.Bulleted);
								}}
							>
								<BulletedListIcon />
							</IconButton>
						)}
						{(!renderedOptions || renderedOptions.bold) && (
							<IconButton
								color={
									focused && currentInlineStyle.has(Style.Bold)
										? "primary"
										: "default"
								}
								onMouseDown={(
									e: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									e.preventDefault();
									toggleInlineStyle(Style.Bold);
								}}
							>
								<BoldIcon />
							</IconButton>
						)}
						{(!renderedOptions || renderedOptions.Italic) && (
							<IconButton
								color={
									focused && currentInlineStyle.has(Style.Italic)
										? "primary"
										: "default"
								}
								onMouseDown={(
									e: React.MouseEvent<HTMLButtonElement, MouseEvent>
								) => {
									e.preventDefault();
									toggleInlineStyle(Style.Italic);
								}}
							>
								<ItalicIcon />
							</IconButton>
						)}
					</div>
					<div className={classes.undoRedoButtons}>
						<IconButton
							disabled={undoStack.size <= 0}
							onMouseDown={(
								e: React.MouseEvent<HTMLButtonElement, MouseEvent>
							) => {
								e.preventDefault();
								handleUndo();
							}}
						>
							<UndoIcon />
						</IconButton>
						<IconButton
							disabled={redoStack.size <= 0}
							onMouseDown={(
								e: React.MouseEvent<HTMLButtonElement, MouseEvent>
							) => {
								e.preventDefault();
								handleRedo();
							}}
						>
							<RedoIcon />
						</IconButton>
						<Button
							disabled={undoStack.size <= 0}
							onMouseDown={(
								e: React.MouseEvent<HTMLButtonElement, MouseEvent>
							) => {
								e.preventDefault();
								handleUndoAll();
							}}
						>
							Discard Changes
						</Button>
					</div>
				</div>
				<div
					className={classes.editor}
					onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
						// Prevent in-editor clicks from triggering the background focus handler
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<Editor
						ref={(ref) => (editor.current = ref)}
						editorState={editorState}
						onChange={onChange}
						placeholder={placeholder}
						handleKeyCommand={handleKeyCommand}
						handleReturn={handleReturn}
						blockRenderMap={customBlockRenderMap}
						onFocus={() => setFocused(true)}
						onBlur={() => {
							if (onBlur) {
								onBlur();
							}
							setFocused(false);
						}}
					/>
				</div>
			</div>
			<Counter
				type={CounterType.Char}
				limit={charLimit}
				contentState={contentState}
			/>
			<Counter
				type={CounterType.Word}
				limit={wordLimit}
				contentState={contentState}
			/>
			<Counter
				type={CounterType.Line}
				limit={lineLimit}
				contentState={contentState}
			/>
		</>
	);
};
