import React from "react";
import { useHistory } from "react-router-dom";

import { useLogOutMutation } from "../../types/graphql-types";

import {
	makeStyles,
	IconButton,
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	MenuList,
	MenuItem,
	Theme,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "inline-block",
	},
	vertColor: {
		color: theme.color.black.main,
	},
	popper: {
		top: "unset",
		left: "unset",
		right: 0,
		transform: "unset",
		width: "fit-content",
	},
	menu_list: {
		marginTop: 4,
		[theme.breakpoints.up(theme.customBreakpoints.md)]: {
			marginTop: 8,
		},
	},
}));

export const ActionMenu: React.FC<{}> = () => {
	const history = useHistory();
	const classes = useStyles();
	const [open, setOpen] = React.useState<boolean>(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);
	const [logout, { loading }] = useLogOutMutation({
		variables: {},
	});

	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};

	const handleClose = async (event: React.MouseEvent<EventTarget>) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}

		setOpen(false);
	};

	const handleLogout = async (event: React.MouseEvent<EventTarget>) => {
		handleClose(event);

		const result = await logout();

		if (result?.data?.logOut) {
			history.push("/login");
		} else if (result?.errors) {
			// TODO: On failed logout, delete the sid cookie and hard refresh
		}
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpen(false);
		}
	}

	React.useEffect(() => {
		if (!open) {
			anchorRef.current?.focus();
		}
	}, [open]);

	return (
		<div className={classes.root}>
			<IconButton
				ref={anchorRef}
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleToggle}
			>
				<MoreVertIcon className={classes.vertColor} />
			</IconButton>
			<Popper
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				className={classes.popper}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === "bottom" ? "center top" : "center bottom",
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList
									autoFocusItem={open}
									id="menu-list-grow"
									onKeyDown={handleListKeyDown}
									className={classes.menu_list}
								>
									<MenuItem disabled={loading} onClick={handleLogout}>
										Logout
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};
