import { makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import React from "react";
import { DraggableComponentProps } from "../../components/DraggableList";
import { HeightCircle } from "../../icons";
import { HomeOptionsWidget, Templates } from "../../types/graphql-types";
import { WidgetImageMapper } from "./WidgetImageMapper";

const useStyles = makeStyles((theme: Theme) => ({
	sortable_list_item: {
		justifyContent: "space-between",
		alignItems: "center",
		height: "fit-content",
		backgroundColor: theme.color.grey.light,
		padding: 0,
		marginBottom: theme.spacing(1),
		borderRadius: theme.shape.borderRadius,
	},
	list_item_dropping: {
		animationName: "$scale-down",
		animationDuration: "250ms",
		animationIterationCount: "1",
		animationFillMode: "forwards",
	},
	list_item_dragging: {
		animationName: "$scale-up",
		animationDuration: "250ms",
		animationIterationCount: "1",
		animationFillMode: "forwards",
	},
	img: {
		width: "100%",
		background: "white",
	},
	sortableImg: {
		border: `1px dashed ${theme.color.grey.main}`,
		padding: 4,
		marginBottom: -5,
	},
	icon_container: {
		width: 30,
		display: "flex",
		alignItems: "center",
		height: 0,
		overflow: "visible",
		position: "relative",
		top: 20,
		left: 122,
	},
	icon: {
		width: 30,
		height: 30,
	},
	"@keyframes scale-down": {
		from: {
			transform: "scale(1.1)",
			boxShadow: "0px 0px 5px 3px #CCCCCC",
		},
		to: {
			transform: "scale(1)",
			boxShadow: "none",
		},
	},
	"@keyframes scale-up": {
		from: {
			transform: "scale(1)",
			boxShadow: "none",
		},
		to: {
			transform: "scale(1.1)",
			boxShadow: "0px 0px 5px 3px #CCCCCC",
		},
	},
}));

interface SortableWidgetProps
	extends DraggableComponentProps<HomeOptionsWidget> {
	template: Templates;
}

export const SortableWidget: React.FC<SortableWidgetProps> = (props) => {
	const { item, provided, snapshot, template } = props;
	const classes = useStyles(props);

	return (
		<div
			className={classes.sortable_list_item}
			ref={provided.innerRef}
			{...provided.draggableProps}
			{...provided.dragHandleProps}
		>
			<div
				className={clsx({
					[classes.list_item_dropping]: snapshot.isDropAnimating,
					[classes.list_item_dragging]:
						snapshot.isDragging && !snapshot.isDropAnimating,
				})}
			>
				{item.moveable && (
					<div className={classes.icon_container}>
						<HeightCircle className={classes.icon} />
					</div>
				)}
				<img
					src={WidgetImageMapper(item, template)}
					alt=""
					className={clsx({
						[classes.img]: true,
						[classes.sortableImg]: item.moveable,
					})}
				/>
			</div>
		</div>
	);
};
