import React from "react";

import { makeStyles, Typography, Theme } from "@material-ui/core";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { DraggableComponentProps } from "../../components/DraggableList";

import { TeamMember as TeamMemberType } from "../../types/graphql-types";
import { UseGlobalDarkModeContext } from "../../components/GlobalDarkMode";

interface ThemeProps {
	darkMode: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
	sortable_list_item: ({ darkMode }: ThemeProps) => ({
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: 48,
		backgroundColor: theme.color.grey.light,
		padding: `0 1rem 0 0`,
		marginBottom: 2,
		borderRadius: theme.shape.borderRadius,
		"&:nth-child(odd)": {
			backgroundColor: darkMode
				? theme.color.white.dark
				: theme.color.white.main,
		},
	}),

	flex_col: {
		flex: 1,
	},
	handle: {
		cursor: "move",
		marginRight: theme.spacing(2),
		padding: `1rem`,
	},
	drag_icon: {
		color: theme.color.grey.dark,
	},
	remove_icon: {
		color: theme.color.grey.dark,
		cursor: "pointer",
		marginLeft: theme.spacing(2),
	},

	draggable_list_item: {
		position: "fixed",
		pointerEvents: "none",
		boxSizing: "border-box",
	},
}));

type SortableTeamMemberProps = DraggableComponentProps<TeamMemberType>;

export const SortableTeamMember: React.FC<SortableTeamMemberProps> = ({
	item,
	provided,
}) => {
	const { isDarkMode } = UseGlobalDarkModeContext();
	const classes = useStyles({ darkMode: isDarkMode });

	return (
		<div
			className={classes.sortable_list_item}
			ref={provided.innerRef}
			{...provided.draggableProps}
		>
			<div className={classes.handle} {...provided.dragHandleProps}>
				<DragIndicatorIcon className={classes.drag_icon} />
			</div>
			<div className={classes.flex_col}>
				<Typography>{`${item.lastName} ${item.firstName}`}</Typography>
			</div>
		</div>
	);
};
