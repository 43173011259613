import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	makeStyles,
	Theme,
} from "@material-ui/core";
import { Tooltip, TooltipTypes } from "../../components/Tooltip";

const useStyles = makeStyles((theme: Theme) => ({
	text: {
		marginTop: theme.spacing(2),
	},
}));

type DeployChangesDialogProps = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	onPublish: () => void;
};

export const DeployChangesDialog: React.FC<DeployChangesDialogProps> = ({
	open,
	setOpen,
	onPublish,
}) => {
	const classes = useStyles();
	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<DialogTitle>Deploy Changes</DialogTitle>
			<DialogContent>
				<Tooltip
					type={TooltipTypes.Warning}
					text={
						<React.Fragment>
							All saved website changes are reflected immediately in the
							database and will go live within 48 hours. If you need your
							changes to go live immediately, you can use this screen to deploy
							your site.
						</React.Fragment>
					}
				/>
				<div className={classes.text}>
					Are you sure you want to immediately deploy all changes? Changes may
					take several minutes to propagate.
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)} color="secondary">
					no
				</Button>
				<Button onClick={onPublish} color="primary">
					yes
				</Button>
			</DialogActions>
		</Dialog>
	);
};
