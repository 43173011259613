import React from "react";
import {
	KeyboardDatePicker,
	KeyboardDatePickerProps,
} from "@material-ui/pickers";
import { TextField } from "../TextField";

export type DateFieldProps = Omit<KeyboardDatePickerProps, "error"> & {
	label?: string;
	error?: string;
	helperText?: string;
};

export const DateField: React.FC<DateFieldProps> = (props) => {
	return (
		<KeyboardDatePicker
			{...(props as KeyboardDatePickerProps)}
			fullWidth
			TextFieldComponent={
				TextField as KeyboardDatePickerProps["TextFieldComponent"]
			}
		/>
	);
};
