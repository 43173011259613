import React, { FC, createContext, useContext, useMemo, useState } from "react";
import { makeStyles, Modal, CircularProgress } from "@material-ui/core";

interface LoadingIndicatorProps {
	loading: boolean;
}

interface GlobalLoadingIndicatorContextValues {
	loadingIndicatorProps: LoadingIndicatorProps;
	setLoadingIndicatorProps: (props: LoadingIndicatorProps) => void;
}

const GlobalLoadingIndicatorContext = createContext<GlobalLoadingIndicatorContextValues>(
	{
		loadingIndicatorProps: {
			loading: false,
		},
		setLoadingIndicatorProps: () => {
			// eslint-disable-next-line no-console
			console.error(
				"Called setLoadingIndicatorProps outside of context provider."
			);
		},
	}
);

const useStyles = makeStyles(() => ({
	modal: {
		border: "none",
		outline: "none",
	},
	progress: {
		position: "absolute",
		top: "50%",
		left: "50%",
		outline: "none",
	},
}));

export const GlobalLoadingIndicatorProvider: FC<{}> = ({ children }) => {
	const classes = useStyles();

	const defaultLoadingIndicatorProps: Required<LoadingIndicatorProps> = {
		loading: false,
	};

	const [loadingIndicatorProps, setLoadingIndicatorProps] = useState(
		defaultLoadingIndicatorProps
	);

	const value: GlobalLoadingIndicatorContextValues = useMemo(
		() => ({
			loadingIndicatorProps,
			setLoadingIndicatorProps,
		}),
		[loadingIndicatorProps, setLoadingIndicatorProps]
	);

	return (
		<GlobalLoadingIndicatorContext.Provider value={value}>
			<Modal
				open={loadingIndicatorProps.loading}
				className={classes.modal}
				disableAutoFocus={false}
			>
				<CircularProgress className={classes.progress} />
			</Modal>
			{children}
		</GlobalLoadingIndicatorContext.Provider>
	);
};

export const useGlobalLoadingIndicator = () =>
	useContext(GlobalLoadingIndicatorContext);
